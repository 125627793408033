import { TestComponent } from './test/test.component';
import { params as TestComponentParams } from './test/test.params';

export let animations = [
  {
    id: 'overlays',
    label: 'Video overlays',
    values: [
      {
        id: 'test-component-01',
        name: 'Test component T01',
        component: TestComponent,
        params: TestComponentParams,
      },
    ],
  },
];
