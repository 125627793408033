import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { saveAs } from 'file-saver';
import { RenderService } from '../../../services/render.service';
import { LivechartsService } from '../../../livecharts.service';

@Component({
  selector: 'lib-render-video-dialog',
  templateUrl: './render-video-dialog.component.html',
  styleUrls: ['./render-video-dialog.component.scss'],
})
export class RenderVideoDialogComponent implements OnInit, OnDestroy {
  public rendering = false;
  public aborted = false; // When user aborts
  public errored = false; // When the server sends an error
  public finished = false;
  public message: string;
  public error: string;
  // private messageSubscription: any;
  private errorSubscription: any;
  private instance;
  constructor(
    private dialogRef: MatDialogRef<RenderVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public renderService: RenderService,
    private livechartsService: LivechartsService,
  ) {
    this.instance = data.instance;
  }

  ngOnInit() {
    this.errorSubscription = this.renderService.errorObservable.subscribe(
      errorMessage => {
        this.error = errorMessage;
        this.errored = true;
        this.rendering = false;
      },
    );
  }
  ngOnDestroy() {
    // this.messageSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }
  public renderOnServer(videoOutput) {
    let promise;
    this.rendering = true;
    this.instance.beforeRender();
    promise = this.renderService.renderVideoOnServer(
      this.livechartsService.settings,
      this.instance.animation,
      this.livechartsService.frameRate,
      videoOutput,
      this.instance.svgElement,
      this.instance.getParameterValue('size')[0],
      this.instance.getParameterValue('size')[1],
      // this.data.canvas,
    );
    promise.then(({ blob, fileName }) => {
      // console.log(blob);
      this.rendering = false;
      this.finished = true;
      saveAs(blob, fileName);
      this.instance.afterRender();
    });
  }
  public abort() {
    this.renderService.abort();
    this.aborted = true;
    this.rendering = false;
    // this.dialogRef.close();
  }
  public close() {
    this.dialogRef.close();
  }
  public renderWebM() {
    let promise;
    this.rendering = true;
    this.instance.beforeRender();
    promise = this.renderService.renderVideoInClient(
      this.livechartsService.settings,
      this.instance.animation,
      this.livechartsService.frameRate,
      this.instance.svgElement,
      this.instance.getParameterValue('size')[0],
      this.instance.getParameterValue('size')[1],
      // this.data.canvas,
      null,
      this.instance.parameters.metadata.backgroundColor || '#FFF',
    );
    promise.then(blob => {
      // console.log(blob);
      this.rendering = false;
      this.finished = true;
      saveAs(blob, 'video.webm');
      this.instance.afterRender();
    });
  }
  public renderMP4() {
    this.renderOnServer('smallAndCompatible');
  }
  public renderMov() {
    this.renderOnServer('highQualityAndTransparent');
  }
}
