import { Component, OnInit } from '@angular/core';
import { ControlComponent } from '../base/control.component';
import { LivechartsService } from '../../../../livecharts.service';

@Component({
  selector: 'lib-standard-checkbox',
  templateUrl: './standard-checkbox.component.html',
  styleUrls: ['./standard-checkbox.component.scss'],
})
export class StandardCheckboxComponent extends ControlComponent {
  constructor(public livechartsService: LivechartsService) {
    super(livechartsService);
  }
}
