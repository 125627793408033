import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material/material.module';
import { AnimationsModule } from './animations/animations.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// TOGGLE_LCS_HERE
// import { LivechartsModule } from 'livecharts';
import { LivechartsModule } from '../../../livecharts/src/lib/livecharts.module';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    AnimationsModule.forRoot(),
    BrowserAnimationsModule,
    LivechartsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
