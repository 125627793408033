import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LivechartsService } from '../../livecharts.service';

@Component({
  selector: 'lib-basic-overview',
  templateUrl: './basic-overview.component.html',
  styleUrls: ['./basic-overview.component.scss'],
})
export class BasicOverviewComponent implements OnInit {
  public animations: any;
  public currentGroup: any;
  public errorMessage: string;
  // private animationSubscription: any;

  constructor(
    private livechartsService: LivechartsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    // this.animations = this.livechartsService.getAnimations();
    // this.livechartsService.animationObservable.subscribe(animations => {
    //   if (animations) {
    //     this.animations = animations;
    //     this.init();
    //   }
    // });
    this.livechartsService.setPlaybackCommand(
      this.livechartsService.settings.overview.playing,
    );
    this.animations = this.livechartsService.animations;
  }
  public init() {
    // On being routed to a group show the animations for that group
    this.route.params.subscribe(params => {
      let groupID = params['overviewGroup'];
      if (groupID) {
        // Dig up the group if we have the overviewGroup parameter
        this.currentGroup = this.animations.find(group => group.id === groupID);
        // Show an error for unknown groups
        if (!this.currentGroup) {
          this.errorMessage = `Please check your URL, unknown overview group: ${groupID}.`;
        }
      } else {
        // Route to the first group
        groupID = this.animations[0]['id'];
        this.router.navigate([
          `/${this.livechartsService.defaultSettings.paths.overview}/`,
          groupID,
        ]);
      }
    });
  }
  public selectGroup(group) {
    this.router.navigate([
      `/${this.livechartsService.defaultSettings.paths.overview}/`,
      group.id,
    ]);
  }
}
