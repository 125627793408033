import { NgModule } from '@angular/core';
import { LivechartsModule } from '../../../livecharts/src/lib/livecharts.module';
import { Routes, RouterModule } from '@angular/router';
// TOGGLE_LCS_HERE
// import { BasicOverviewComponent } from 'livecharts';
// import { BasicEditorComponent } from 'livecharts';
import { BasicOverviewComponent } from '../../../livecharts/src/lib/overview/basic-overview/basic-overview.component';
import { BasicEditorComponent } from '../../../livecharts/src/lib/editor/basic-editor/basic-editor.component';

// You can change the routing, just also change the routing in the settings
const routes: Routes = [
  { path: 'editor', component: BasicEditorComponent },
  { path: 'editor/:animationID', component: BasicEditorComponent }, // TODO: move to the editor module
  { path: 'overview', component: BasicOverviewComponent },
  { path: 'overview/:overviewGroup', component: BasicOverviewComponent }, // TODO: move to the overview module
  { path: '', redirectTo: '/overview', pathMatch: 'full' },
];

@NgModule({
  imports: [LivechartsModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
