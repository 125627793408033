import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { merge } from 'lodash';
import { settings } from './settings';
import {
  Parameter,
  PlaybackCommand,
  PlaybackState,
  Progress,
} from './types/types';
import * as d3 from 'd3';

// Basically, this entire service wires up the application
// It contains methods to pass the implementation specific animations and settings to
// and it contains streams and observables that are used to pass all the necessary info around
@Injectable({
  providedIn: 'root',
})
export class LivechartsService {
  public animations: any; // Passed in from the implementing project
  public playing = true; // Animation state
  public frameRate; // Framerate for recording output
  // Settings
  public defaultSettings = settings;
  public settings;
  private scalableStream = new Subject<boolean>();
  public scalableObservable = this.scalableStream.asObservable();
  private showSlicesStream = new Subject<boolean>();
  public showSlicesObservable = this.showSlicesStream.asObservable();
  // Parameters
  private parameterStream = new Subject<Parameter>(); // New parameter values from controls
  public parameterObservable = this.parameterStream.asObservable();
  private timingStream = new Subject<Parameter>(); // New timing values from controls
  public timingObservable = this.timingStream.asObservable();
  private resetParameterStream = new Subject<boolean>(); // Stream that tells all controls to reset themselves
  public resetParameterObservable = this.resetParameterStream.asObservable();
  private setParameterStream = new Subject<any>(); // Used when parameters are set from a stored value
  public setParameterObservable = this.setParameterStream.asObservable();
  private settingsStream = new Subject<any>(); // Used when the settings control emits new values
  public settingObservable = this.settingsStream.asObservable();
  // Progress
  private setProgressStream = new Subject<number>(); // Call the setProgress method on this servive
  public setProgressObservable = this.setProgressStream.asObservable(); // Only used by the animation
  private setTimeStream = new Subject<number>(); // Call the setTime method on this servive
  public setTimeObservable = this.setTimeStream.asObservable(); // Only used by the animation
  private getProgressStream = new Subject<Progress>();
  public getProgressObservable = this.getProgressStream.asObservable(); // Used by everything that needs to know the progress of the animation
  // Playback
  private playbackStream = new Subject<PlaybackCommand>(); // Call the sendPlayBackCommand method to tell the animation to do something
  public playbackObservable = this.playbackStream.asObservable(); // The animation observes this stream and this stream only for playback control
  // Check playingStream works as a Subject or whether it should be a BehaviorSubject (with the initial state being set through the settings)
  private playingStream = new Subject<PlaybackState>();
  public playingObservable = this.playingStream.asObservable();
  // Recording

  constructor() {}
  public setAnimations(animations: any) {
    this.animations = animations;
    // this.animationSubject.next(this.animations);
  }
  public getAnimationsByGroup(group: string) {
    let result = this.animations[group];
    return result;
  }
  public getAnimationById(id: string) {
    let animation = this.animations
      .map(obj => obj.values)
      .flatMap(arr => arr)
      .find(obj => obj['id'] === id);
    return animation;
  }
  public setSettings(customSettings) {
    this.settings = merge(this.defaultSettings, customSettings);
    this.frameRate = this.settings.frameRate.default;
    this.injectAllFonts(); // Not so sure this is the best place, perhaps move to separate method, init() of some sort?
  }
  public pushParameter(parameter: Parameter) {
    this.parameterStream.next(parameter);
  }
  public pushTiming(timing: Parameter) {
    this.timingStream.next(timing);
  }
  public resetParameters() {
    this.resetParameterStream.next(true);
  }
  public setParameter(id: string, value: any) {
    this.setParameterStream.next({ id, value });
  }
  public setProgress(progress: number) {
    // Method used by a control to tell the animation to go to some value for progress
    this.setProgressStream.next(progress);
  }
  public setTime(progress: number) {
    // Method used by a control to tell the animation to go to some time in the timeline
    this.setTimeStream.next(progress);
  }
  public setSetting(id: string, value: any) {
    this.settingsStream.next({ id, value });
  }
  public setScalable(scalable: boolean) {
    this.scalableStream.next(scalable);
  }
  public setShowSlices(showSlices: boolean) {
    this.showSlicesStream.next(showSlices);
  }
  public sendProgress(progress: Progress) {
    // Method used by the animation to inform the rest of the app what the current progress is
    this.getProgressStream.next(progress);
  }
  public setPlaybackCommand(command: PlaybackCommand) {
    // Method used by controls to tell the animation what to do
    let playing = (p: boolean) => {
      this.playing = p;
      this.playingStream.next(p ? 'playing' : 'paused');
    };
    switch (command) {
      case 'play':
        playing(true);
        break;
      case 'pause':
        playing(false);
        break;
      case 'restart':
        playing(true);
        break;
      case 'toggle':
        playing(!this.playing);
        break;
    }
    this.playbackStream.next(command);
  }
  public injectAllFonts() {
    // Inject the CSS for all the fonts into the head of the page
    // this is for presentation purposes in the app itself.
    // In order for images and animations to render properly the fonts
    // also need to be base64 encoded and stuck inside the SVG, that's done
    // by calling beforeRender() on the LC itself which calls encodeFonts().
    let fontSettings = this.settings['fonts'];
    let headSelection = d3.select(document.head);
    for (let fontSetting of fontSettings) {
      switch (fontSetting.type) {
        case 'google':
          headSelection
            .append('link')
            .attr('href', fontSetting.url)
            .attr('rel', 'stylesheet');
          break;
        case 'assets':
          let fontCSS = `@font-face {
            font-family: '${fontSetting.fontFamily}';
            font-style: ${fontSetting.fontStyle};
            font-weight: ${fontSetting.fontWeight};
            font-display: swap;
            src: url(assets/fonts/${fontSetting.fileName}) format('woff2');
          }`;
          headSelection.append('style').text(fontCSS);
          break;
      }
    }
  }
}
