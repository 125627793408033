import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { VideoRecorder } from './videoRecorder';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root',
})
export class RenderService {
  public rendering = false;
  public aborted = false;
  private videoRecorder: VideoRecorder;
  private messageStream = new Subject<string>();
  public messageObservable = this.messageStream.asObservable();
  private errorStream = new Subject<string>();
  public errorObservable = this.errorStream.asObservable();
  constructor() {}
  public createCanvasElement(width: number, height: number): HTMLCanvasElement {
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
  public renderPNGToBlob(
    svgElement: SVGElement,
    width: number,
    height: number,
    canvas?: HTMLCanvasElement,
  ) {
    return this.renderSVGToPNG(
      this.getSVGClone(width, height, svgElement),
      this.getCanvas(width, height, canvas),
    );
    // return this.renderSVGToPNGNew(
    //   this.getSVGClone(width, height, svgElement),
    //   this.getCanvas(width, height, canvas),
    //   'blob',
    // );
  }
  public getSVGClone(
    width: number,
    height: number,
    svgElement: SVGElement,
  ): SVGElement {
    // Copy SVG Element because it will be modified
    let svgCopy = svgElement.cloneNode(true) as SVGElement; // Type asserion necessary because inferred type is Node(?)
    // Give the copy of the SVG Element the correct width and height
    svgCopy.setAttribute('width', width.toString());
    svgCopy.setAttribute('height', height.toString());
    return svgCopy;
  }
  public getCanvas(
    width: number,
    height,
    canvas?: HTMLCanvasElement,
  ): HTMLCanvasElement {
    // A canvas can be passed in (and shown on the screen) or just created
    // for rendering purposes only
    if (!canvas) {
      canvas = document.createElement('canvas');
    }
    // Correct width and height set to canvas
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
  public renderSVGToPNG(svgElement: SVGElement, canvas: HTMLCanvasElement) {
    // Older method to create a PNG, figure out which one is better
    // renderPNGToBlob or renderPNG
    let promise = new Promise(function(resolve, reject) {
      let context = canvas.getContext('2d');
      let svgString = new XMLSerializer().serializeToString(svgElement);
      // TODO: unescape seems to be deprecated and I can't remember why it
      // was included here. But it breaks when I take it out so...
      // Figure that shit out at some point (see link for a starting point).
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#Unicode_strings
      let imgSrc =
        'data:image/svg+xml;base64,' +
        btoa(unescape(encodeURIComponent(svgString))); // It really doesn't work without unescape
      let image = document.createElement('img');
      image.onload = () => {
        context.drawImage(image, 0, 0);
        canvas.toBlob(resolve);
      };
      image.setAttribute('src', imgSrc);
      image.onerror = function() {
        reject('Creating png from image failed');
      };
    });
    return promise;
  }
  public renderSVGToPNGNew(
    // New method to render to PNG
    // In Chrome it seems the created URL's are not removed when revoked
    // don't know if this results in a memory leak.
    svgElement: SVGElement,
    canvas: HTMLCanvasElement,
    output: 'blob' | 'url',
  ): Promise<Blob | string> {
    let promise = new Promise<Blob | string>(function(resolve, reject) {
      let context = canvas.getContext('2d');
      let svgString = new XMLSerializer().serializeToString(svgElement);
      let img = new Image();
      let svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      let url = URL.createObjectURL(svg);
      img.onload = function() {
        context.drawImage(img, 0, 0);
        switch (output) {
          case 'url':
            let png = canvas.toDataURL('image/png');
            resolve(png);
            break;
          case 'blob':
            canvas.toBlob(resolve);
            break;
        }
        URL.revokeObjectURL(url);
      };
      img.src = url;
    });
    return promise;
  }
  public renderSlicesToBlobs(
    svgElement: SVGElement,
    inputWidth: number,
    inputHeight: number,
    outputWidth: number,
    outputHeight: number,
    frame,
    slices,
    canvas?: HTMLCanvasElement,
  ) {
    if (outputWidth / outputHeight !== frame[0] / frame[1]) {
      throw new Error(
        'The proportion of the output width and height should be equal to the proportion of the frame width and height, please check parameters.',
      );
    }
    let promises = [];
    for (let slice of slices) {
      let svgCopy = this.getSVGClone(inputWidth, inputHeight, svgElement);
      svgCopy.setAttribute('x', (-1 * slice[0]).toString());
      svgCopy.setAttribute('y', (-1 * slice[1]).toString());
      let svgFrame = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg',
      );
      svgFrame.setAttribute('width', outputWidth.toString());
      svgFrame.setAttribute('height', outputHeight.toString());
      svgFrame.setAttribute('viewBox', `0 0 ${frame[0]} ${frame[1]}`);
      svgFrame.appendChild(svgCopy);
      let p = this.renderPNGToBlob(svgFrame, outputWidth, outputHeight, canvas);
      promises.push(p);
    }
    let promise = Promise.all(promises);
    return promise;
  }
  public abort() {
    this.rendering = false;
    this.aborted = true;
    if (this.videoRecorder) {
      this.videoRecorder.abort();
    }
  }
  public renderVideoInClient(
    settings: any, // Not being used at the moment but passed in just in case :)
    timeline: any,
    frameRate: number,
    svgElement: SVGElement,
    width: number,
    height: number,
    canvas?: HTMLCanvasElement,
    backgroundColor?: string,
  ) {
    // VideoRecorder is a fairly old class from the CBS Livecharts implementation
    this.videoRecorder = new VideoRecorder(
      svgElement,
      timeline,
      frameRate,
      width,
      height,
      canvas,
      backgroundColor,
    );
    let progressSubscription = this.videoRecorder.progressObservable.subscribe(
      p => {
        // console.log(p);
        let progressString = (p * 100).toFixed();
        // Progress info is passed from the progressObservable to the messageStream here
        this.messageStream.next(
          `Rendering video, progress: ${progressString}%.`,
        );
        // this.progressEmitter.emit(p);
      },
    );
    // record() records the video in real time
    // recordFramesToCache() first stores all images in an array and then records the canvas
    let promise = this.videoRecorder.record().then(blob => {
      progressSubscription.unsubscribe();
      return blob;
    });
    return promise;
  }
  public renderVideoOnServer(
    settings: any,
    timeline: any,
    frameRate: number,
    videoOutput: 'smallAndCompatible' | 'highQualityAndTransparent',
    svgElement: SVGElement,
    width: number,
    height: number,
    canvas?: HTMLCanvasElement,
  ) {
    // This method opens a WebSocket, sends a Manifest object and then the first frame of the animation
    // once the frame has been stored on the server, the server sends a 'nextFrame' message until
    // the last frame has been sent, the client then sends a 'render' message, this prompts the
    // server to start creating the video with FFmpeg, when it is done is sends a 'save' message to the client
    // this message is currently superfluous, after the save message the server sends a blob with the video...
    // NB - all frames are sent sequentially, not sure if it's possible or better to send them parallel
    this.rendering = true;
    this.aborted = false;
    let playing = timeline.playing; // Hold on to the state of the timeline and set back when done
    let progress = timeline.progress(); // Same with progress
    let currentFrame = 0;
    let time = timeline.duration();
    let frames = time * frameRate;
    // The manifest is an object sent to the server to announce what's coming
    let manifest = {
      type: 'manifest',
      client: settings.client,
      project: settings.project,
      frameRate,
      videoOutput,
      frames,
      width,
      height,
    };
    let promise = new Promise((resolve, reject) => {
      let fileName: string;
      fileName =
        videoOutput === 'smallAndCompatible'
          ? 'livechart.mp4'
          : 'livechart.mov';
      let nextFrame = () => {
        // Method called when server is ready to receive a new frame
        // if there are still frames to be sent and shizzle has not been cancelled,
        // send the next one
        if (!this.aborted) {
          if (currentFrame < frames) {
            // Go to the progress for the next frame
            let p = currentFrame / frames;
            sendProgress(currentFrame, frames, p);
            timeline.progress(p);
            // Create an image
            let pngPromise = this.renderPNGToBlob(
              svgElement,
              width,
              height,
              // 'blob',
              canvas,
            );
            // When the image has been hatched, send it over the WebSocket
            pngPromise.then((png: Blob) => {
              ws.send(png);
              currentFrame++;
            });
          } else {
            // Send message to the server to start rendering the video
            let msg = {
              type: 'render',
            };
            ws.send(JSON.stringify(msg));
            // Reset the timeline to where it was before
            timeline.progress(progress);
            // If it was playing before, set it to playing again
            if (playing) {
              timeline.play();
            }
          }
        } else {
          let msg = {
            type: 'abort',
          };
          ws.send(JSON.stringify(msg));
          if (playing) {
            timeline.play();
          }
          // this.aborted = false;
        }
      };
      let sendProgress = (frm: number, frms: number, prgrs: number) => {
        let progressString = (prgrs * 100).toFixed();
        let msg = `Sending frame ${frm +
          1} of ${frms}, progress: ${progressString}%.`;
        this.messageStream.next(msg);
      };
      let showMessage = msg => {
        // console.log(msg);
        this.messageStream.next(msg);
      };
      let showError = err => {
        // console.log(err);
        this.rendering = false;
        this.aborted = true;
        this.errorStream.next(err);
      };
      // WebSocket bits starts here
      let ws = new WebSocket(settings.urls.renderVideoOnServer);
      ws.onopen = function(event) {
        // Stop the timeline
        if (playing) {
          timeline.pause();
        }
        // Kick it all off with the manifest object
        ws.send(JSON.stringify(manifest));
      };
      // The onMessage callback is called whenever any message is received,
      // depending on the type of message (string / blob) a different
      // function is called
      let onMessage = event => {
        // console.log(typeof event.data);
        switch (typeof event.data) {
          case 'string':
            onString(event.data);
            break;
          case 'object':
            onBlob(event.data);
            break;
        }
      };
      // Called when a string message (JSON) is received
      let onString = jsonString => {
        let msg = JSON.parse(jsonString);
        // console.log(msg);
        switch (msg.action) {
          case 'nextFrame':
            nextFrame();
            break;
          case 'save':
            // In an earlier implementation the fileName was set based on the 'save' message
            // coming back from the server, it is now set on initialisation on the client
            // fileName = msg.fileName;
            break;
          case 'message':
            showMessage(msg.message);
            break;
          case 'error':
            showError(msg.error);
            break;
          default:
            throw new Error(`Unknown action: ${msg.action}`);
            break;
        }
      };
      // Called when a blob is received (that's the video)
      let onBlob = data => {
        // console.log(data);
        let blob = new Blob([data]);
        // saveAs(blob, fileName);
        resolve({ blob, fileName });
        this.rendering = false;
      };
      ws.onmessage = onMessage;
    });
    return promise;
  }
}
