import { Component, OnInit, Input } from '@angular/core';
// import { ControlsService } from '../controls.service';

// Wraps a group of controls
@Component({
  selector: 'lib-control-group',
  templateUrl: './control-group.component.html',
  styleUrls: ['./control-group.component.scss'],
})
export class ControlGroupComponent implements OnInit {
  @Input() public group: any;
  @Input() public controls: any;
  // constructor(private controlsService: ControlsService) {}

  ngOnInit() {
    // console.log(this.group);
    // console.log(this.controls);
  }
}
