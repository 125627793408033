import { Injectable } from '@angular/core';
import { controls } from './controls';

@Injectable({
  providedIn: 'root', // Singleton service
})
export class ControlsService {
  private controls = controls;
  constructor() {}
  public getControlByType(id: string) {
    let control = this.controls.find(obj => obj['id'] === id);
    return control;
  }
}
