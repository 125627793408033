import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  OnDestroy,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { ControlsService } from '../controls.service';

// Wraps an individual control, receives the key and the settings
// for a control, looks it up, instantiates it and renders the control
@Component({
  selector: 'lib-control-wrapper',
  templateUrl: './control-wrapper.component.html',
  styleUrls: ['./control-wrapper.component.scss'],
})
export class ControlWrapperComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() public id: string;
  @Input() public settings: any;
  @ViewChild('anchor', { read: ViewContainerRef, static: false })
  viewContainerRef;
  private componentRef;
  private control: any;
  private instance: any;
  public errorMessage: string;
  constructor(
    private controlsService: ControlsService,
    private changeDetectorRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    this.control = this.controlsService.getControlByType(
      this.settings['control'],
    );
    if (!this.control) {
      this.errorMessage = `Could not find control for control type: ${this.settings['control']} for control with id: ${this.id}`;
      // throw new Error(
      //   `Could not find control for key: ${this.settings['control']}`,
      // );
    } else {
      this.errorMessage = null;
    }
  }
  ngAfterViewInit() {
    if (this.control) {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        this.control['component'],
      );
      this.viewContainerRef.clear();
      this.componentRef = this.viewContainerRef.createComponent(
        componentFactory,
      );
      this.instance = this.componentRef.instance;
      this.instance.settings = this.settings;
      this.instance.id = this.id;
      this.instance.control = this.settings['control'];
      this.changeDetectorRef.detectChanges();
    }
  }
  ngOnDestroy() {
    this.changeDetectorRef.detach();
  }
}
