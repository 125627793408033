import { Component } from '@angular/core';
import { ControlComponent } from '../base/control.component';
import { LivechartsService } from '../../../../livecharts.service';
import * as _ from 'lodash';

@Component({
  selector: 'lib-standard-select',
  templateUrl: './standard-select.component.html',
  styleUrls: ['./standard-select.component.scss'],
})
export class StandardSelectComponent extends ControlComponent {
  constructor(public livechartsService: LivechartsService) {
    super(livechartsService);
  }
  public getValue() {
    let index = this.form.value;
    let value = this.settings.values[index]['value'];
    return value;
  }
  public setDefault() {
    let index = this.settings.values.findIndex(element =>
      _.isEqual(element.value, this.settings['default']),
    );
    if (index < 0) {
      throw new Error(
        `Value ${this.settings['default']} not found for parameter ${this.id}`,
      );
    }
    this.form.setValue(index);
  }
}
