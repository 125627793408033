import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LivechartsService } from '../../../../livecharts.service';

@Component({
  selector: 'lib-frame-rate',
  templateUrl: './frame-rate.component.html',
  styleUrls: ['./frame-rate.component.scss'],
})
export class FrameRateComponent implements OnInit, OnDestroy {
  private formValueSubscription;
  public formControl = new FormControl();
  public min: number;
  public max: number;
  constructor(private livechartsService: LivechartsService) {}

  ngOnInit() {
    this.min = this.livechartsService.settings.frameRate.min;
    this.max = this.livechartsService.settings.frameRate.max;
    this.formControl.setValue(
      this.livechartsService.settings.frameRate.default,
    );
    this.formValueSubscription = this.formControl.valueChanges.subscribe(
      value => {
        this.formValueChanged(value);
      },
    );
  }
  ngOnDestroy() {
    this.formValueSubscription.unsubscribe();
  }
  public formValueChanged(value) {
    this.livechartsService.frameRate = value;
  }
}
