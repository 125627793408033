import { Component, OnInit, Input } from '@angular/core';
import { LivechartsService } from '../../../livecharts.service';

// Top-level component for the controls
// Creates a tab for parameters, timings and data
@Component({
  selector: 'lib-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnInit {
  public parameters: any;
  public parameterGroups: any[];
  public timingGroups: any[];
  public data: any;
  constructor(public livechartsService: LivechartsService) {}
  ngOnInit() {}
  @Input() set params(params: any) {
    this.parameters = params;
    console.log(this.parameters);
    if (this.parameters) {
      this.parameterGroups = this.parameters['parameterGroups'];
      this.timingGroups = this.parameters['timingGroups'];
      this.data = this.parameters['data'];
    }
  }
}
