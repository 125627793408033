import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}
  public generateID(length: number) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  public test() {
    // https://gist.github.com/viljamis/c4016ff88745a0846b94
    // https://github.com/Fetten/woff2base64/blob/master/src/woff2base64.js
    // let promise = this.http
    //   .get('assets/fonts/AkkoPro-Regular.woff2', { responseType: 'blob' })
    //   .toPromise()
    //   .then(blob => {
    //     // console.log(blob);
    //     let promise2 = new Promise(function(resolve, reject) {
    //       let reader = new FileReader();
    //       reader.onloadend = function() {
    //         let base64data = reader.result;
    //         resolve(base64data);
    //         // console.log(base64data);
    //       };
    //       reader.onerror = function(event) {
    //         reject(new Error(`Base64 encoding failed`));
    //       };
    //       reader.readAsDataURL(blob);
    //     });
    //     return promise2;
    //   });
    // return promise;
  }
}
