import { Component } from '@angular/core';
import { ControlComponent } from '../base/control.component';
import { LivechartsService } from '../../../../livecharts.service';

@Component({
  selector: 'lib-standard-photo',
  templateUrl: './standard-photo.component.html',
  styleUrls: ['./standard-photo.component.scss'],
})
export class StandardPhotoComponent extends ControlComponent {
  public errors: any[];
  public photo;
  public eventHandler; // slaat het event op om het image later te kunnen verwijderen
  constructor(public livechartsService: LivechartsService) {
    super(livechartsService);
  }
  public getValue() {
    let value = this.photo ? this.photo['url'] : null;
    return value;
  }
  public dropHandler(event) {
    event.preventDefault();
    this.eventHandler = event.dataTransfer;
    let file = this.eventHandler.files[0];
    this.setupReader(file);
  }

  private readerLoaded(event, file, obj) {
    obj['url'] = event.target.result;
    this.photo = obj;
    this.doneReading();
  }

  private setupReader(file) {
    if (file.type.match('image/jpeg') || file.type.match('image/png')) {
      let obj = {
        name: file.name,
        type: file.type,
      };
      let reader = new FileReader();
      reader.onload = (event) => {
        this.readerLoaded(event, file, obj);
      };
      reader.readAsDataURL(file);
    } else {
     console.log('ELSE'); // ToDo error handeling
    }
  }

  private doneReading() {
    // console.log(this.photo);
    this.setOutputData(this.photo);
  }

  public dragOverHandler(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  public remove(event) {
    this.eventHandler.clearData();
    // this.setOutputData();
    this.photo = null;
    this.reset();
  }

  public clear() {
    this.reset();
    this.setOutputData();
  }
}
