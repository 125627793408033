import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { LivechartsService } from '../livecharts.service';
import { UtilService } from '../services/util.service';
// import { RenderService } from '../services/render.service';
import { SvgService } from '../services/svg.service';
import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
// import { PixiPlugin } from 'gsap/PixiPlugin'; Register Plugin hier
// NB: to avoid tree shaking of required plugins, register them like so
// gsap.registerPlugin(MotionPathPlugin, ScrollToPlugin, TextPlugin);
// it seems it is enough to do this once here and then just import
// whatever plugins necessary in the components
gsap.registerPlugin(Draggable);
// gsap.registerPlugin(MotionPathPlugin);

import * as d3 from 'd3';
import * as _ from 'lodash';
import { LabelForStill } from '../types/types';
let g: any, s: any;

@Component({
  selector: 'lib-livechart-base',
  template: '',
})
export class LivechartBaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input() public parameters: any;
  public values: any; // Current values of all parameters
  public timings: any; // Same for timing data
  public data: any; // Tabular data structure
  public animation: GSAPTimeline;
  public id: string;
  public scalable: boolean;
  public showSlices: boolean;
  @ViewChild('svg', { static: false }) svgRef: ElementRef;
  public svgElement: SVGElement; // The actual Element node of the animation
  public svgSelection: d3.Selection<SVGElement, any, any, any>; // The D3 selection around the element
  public fontCSS: string; // Huge, big string with all the font CSS
  public fontStyleSelection: d3.Selection<SVGElement, any, any, any>;
  public slices: d3.Selection<SVGElement, any, any, any>; // Group with rectangles showing where the slices are
  private playbackCommandSubscription;
  private progressSubscription;
  private timeSubscription;
  private parameterSubscription;
  private scalableSubscription;
  private showSlicesSubscription;
  constructor(
    public livechartsService: LivechartsService,
    public utilService: UtilService,
    public svgService: SvgService,
  ) {
    g = (id: string) => this.values[id]; // Method to succinctly dig-up a parameter
    s = (id: string, value: any) => {
      // Same but for setting a parameter value
      this.values[id] = value;
    };
  }
  ngOnInit() {
    this.id = this.utilService.generateID(10); // id is used to create unique id's in the SVG to avoid conflicts
    // Listen to the playbackObservable and play / pause / restart the animation when commands come in
    // TODO: for readability, move this to a method
    this.playbackCommandSubscription = this.livechartsService.playbackObservable.subscribe(
      command => {
        switch (command) {
          case 'play':
            this.animation.play();
            break;
          case 'pause':
            this.animation.pause();
            break;
          case 'restart':
            this.animation.restart();
            break;
          case 'toggle':
            this.animation.paused()
              ? this.animation.play()
              : this.animation.pause();
            break;
        }
      },
    );
    this.progressSubscription = this.livechartsService.setProgressObservable.subscribe(
      progress => {
        this.animation.progress(progress);
      },
    );
    this.timeSubscription = this.livechartsService.setTimeObservable.subscribe(
      time => {
        this.animation.time(time);
      },
    );
    this.parameterSubscription = this.livechartsService.parameterObservable.subscribe(
      parameter => {
        // When a new value comes in, check to see if it belongs to the parameters or timings
        // and update the appriopriate object
        if (_.includes(Object.keys(this.parameters.parameters), parameter.id)) {
          // console.log('param');
          this.values[parameter.id] = parameter.value;
          this.createLivechart();
        } else if (
          _.includes(Object.keys(this.parameters.timings), parameter.id)
        ) {
          // console.log('timing');
          this.timings[parameter.id] = parameter.value;
          this.createLivechart();
        }
      },
    );
    this.scalableSubscription = this.livechartsService.scalableObservable.subscribe(
      scalable => {
        this.scalable = scalable;
      },
    );
    this.showSlicesSubscription = this.livechartsService.showSlicesObservable.subscribe(
      showSlices => {
        this.showHideSlices(showSlices);
      },
    );
    this.setAllDefaults();
  }
  ngOnDestroy() {
    this.kill();
    this.playbackCommandSubscription.unsubscribe();
    this.progressSubscription.unsubscribe();
    this.timeSubscription.unsubscribe();
    this.parameterSubscription.unsubscribe();
    this.scalableSubscription.unsubscribe();
    this.showSlicesSubscription.unsubscribe();
  }
  ngAfterViewInit() {
    this.svgElement = this.svgRef.nativeElement;
    this.svgSelection = d3.select(this.svgElement);
    this.encodeFonts();
    this.createLivechart();
    this.drawSlices();
  }
  public getDefaultValues(obj) {
    // Pull the defaults from the parameter file
    if (obj) {
      let defaultValues = {};
      Object.keys(obj).map(key => {
        defaultValues[key] = obj[key]['default'];
      });
      return defaultValues;
    }
  }
  public setAllDefaults() {
    // The naming of this.values is a little awkward but this.parameters was already taken
    // and in the previous version this.values was the name we used so... it is what it is
    this.values = this.getDefaultValues(this.parameters.parameters);
    this.timings = this.getDefaultValues(this.parameters.timings);
    this.data = this.getDefaultValues(this.parameters.data);
  }
  public getParameterValue(id: string) {
    return this.values[id];
  }
  public kill() {
    // Kill animation here in order for animations to be stopped on destroy of LivechartComponents
    if (this.animation) {
      this.animation.kill();
    }
  }
  public createLivechart() {
    // If we had an animation already, get the progress of that animation
    let currentProgress: number;
    if (this.animation) {
      currentProgress = this.animation.progress();
    }
    this.kill();
    this.initializeLivechart();
    this.createElements();
    this.createAnimation();
    // Set the progress on the animation back to what it was
    if (currentProgress) {
      this.animation.progress(currentProgress);
    }
    // Update the scrubber
    if (this.animation) {
      this.livechartsService.sendProgress({
        progress: this.animation.progress(),
        time: this.animation.time(),
        duration: this.animation.duration(),
      });
    }
    // Note: when the timing is being changed, we may not end up
    // at a spot that looks the same because progress is some percentage
    // on the timeline and that spot may look different... of course
  }
  public initializeLivechart() {
    // Do initial setup and calculations here
  }
  public createElements() {
    // Create all the SVG elements that make up the Livechart here
  }
  public createAnimation() {
    // Create the GSAP animations here
    if (this.parameters['metadata']['category'] === 'animation') {
      // The onUpdate callback gets the animation bount to 'this' by GSAP
      // in order to access the livechartsService inside the function
      // an IIFE was used to create a closure around it
      let setProgress = (function(livechartsService) {
        return function() {
          livechartsService.sendProgress({
            progress: this.progress(),
            time: this.time(),
            duration: this.duration(),
          });
        };
      })(this.livechartsService);
      this.animation = gsap.timeline({
        repeat: -1,
        onUpdate: setProgress, // Emit progress value on every update
        // callbackScope: this.animation, // Default callbackScope IS the animation
        paused: !this.livechartsService.playing,
      });
    }
  }
  public findLabelsForStills(): LabelForStill[] {
    // Add labels in the GSAP timeline with the format: 'positionForStill-1'.
    // This method digs up a list of labels with that format,
    // buttons are then added to the UI to stop the animation in
    // those locations in order to generate stills...
    if (this.animation) {
      let labels = this.animation.labels; // Spits out an object with the labels as keys and the times as properties
      let regEx = /^positionForStill-[1-9][0-9]*$/; // Only match entire string with a number at the end without leading zeros
      let keys = Object.keys(this.animation.labels); // Get the keys
      let filteredKeys = _.filter(keys, key => regEx.test(key)); // Filter out all the labels that do not match the pattern
      let filteredLabels = _.map(filteredKeys, key => {
        return {
          label: key,
          time: labels[key],
          nr: parseInt(key.replace('positionForStill-', ''), 10),
        };
      });
      return filteredLabels;
    }
  }
  public encodeFonts() {
    // From the metadata get all the fonts that are used in this Livechart
    let fonts = _.get(this.parameters, ['metadata', 'fonts']);
    if (fonts) {
      this.svgService.generateEmbeddedFontCSS(fonts).then(css => {
        this.fontCSS = css;
        // console.log(css);
      });
    }
  }
  public beforeRender() {
    this.fontStyleSelection = this.svgSelection
      .append('style')
      .text(this.fontCSS)
      .lower();
    if (this.slices) {
      this.slices.attr('visibility', 'hidden');
    }
  }
  public afterRender() {
    this.fontStyleSelection.remove();
    if (this.slices) {
      this.slices.attr('visibility', this.showSlices ? 'visible' : 'hidden');
    }
  }
  public drawSlices() {
    // Check the parameters to see if all conditions are met for drawing some
    // sexy slices
    let hasPngs = _.get(this.parameters, ['metadata', 'outputTypes']).find(
      type => type === 'pngs',
    );
    let frame = _.get(this.parameters, ['metadata', 'frame']);
    let slices = _.get(this.parameters, ['metadata', 'slices']);
    // Draw those slices
    if (hasPngs && frame && slices) {
      this.slices = this.svgSelection
        .append('g')
        .attr('id', `slices-${this.id}`)
        .attr('visibility', this.showSlices ? 'visible' : 'hidden');
      for (let slice of slices) {
        this.slices
          .append('rect')
          .attr('x', slice[0])
          .attr('y', slice[1])
          .attr('width', frame[0])
          .attr('height', frame[1])
          .style('fill', this.livechartsService.settings.slices.fill)
          .style('stroke', this.livechartsService.settings.slices.stroke)
          .style(
            'stroke-width',
            this.livechartsService.settings.slices.strokeWidth,
          )
          .style(
            'stroke-dasharray',
            this.livechartsService.settings.slices.strokeDashArray,
          );
      }
    } else {
      // throw new Error(
      //   `Do not call drawSlices on Livecharts that does not include 'pngs' in outputTypes and / or does not have 'frame' and 'slices' set on metadata.`,
      // );
    }
  }
  public showHideSlices(show: boolean) {
    this.showSlices = show;
    if (this.slices) {
      this.slices.attr('visibility', this.showSlices ? 'visible' : 'hidden');
    }
  }
}
