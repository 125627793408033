import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { LivechartsService } from '../../../livecharts.service';
import { PlaybackState } from '../../../types/types';

@Component({
  selector: 'lib-playback',
  templateUrl: './playback.component.html',
  styleUrls: ['./playback.component.scss'],
})
export class PlaybackComponent implements OnInit {
  @Input()
  public labelsForStills: any[];
  // @Input()
  // public livechartCategory: string;
  @Input()
  public metadata: any;
  public playing$: Observable<PlaybackState>;
  @Output() render = new EventEmitter();
  constructor(private livechartsService: LivechartsService) {
    this.playing$ = this.livechartsService.playingObservable;
  }

  ngOnInit() {}
  public play() {
    this.livechartsService.setPlaybackCommand('play');
  }
  public pause() {
    this.livechartsService.setPlaybackCommand('pause');
  }
  public restart() {
    this.livechartsService.setPlaybackCommand('restart');
  }
  public playPause() {
    this.livechartsService.setPlaybackCommand('toggle');
  }
  public stopForStill(label) {
    this.livechartsService.setPlaybackCommand('pause');
    this.livechartsService.setTime(label.time);
  }
  public renderOfType(type: string) {
    this.render.emit(type);
  }
  public outputType(type: string) {
    return this.metadata['outputTypes'].find(t => t === type);
  }
}
