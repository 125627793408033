import { Component } from '@angular/core';
import { ControlComponent } from '../base/control.component';
import { LivechartsService } from '../../../../livecharts.service';

@Component({
  selector: 'lib-standard-textarea',
  templateUrl: './standard-textarea.component.html',
  styleUrls: ['./standard-textarea.component.scss'],
})
export class StandardTextareaComponent extends ControlComponent {
  constructor(public livechartsService: LivechartsService) {
    super(livechartsService);
  }
}
console.log('hier');
