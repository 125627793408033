// import { Component, OnInit, Input } from '@angular/core';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  OnDestroy,
  ComponentFactoryResolver,
  ChangeDetectorRef,
} from '@angular/core';
import { LivechartsService } from '../../../livecharts.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-basic-overview-element',
  templateUrl: './basic-overview-element.component.html',
  styleUrls: ['./basic-overview-element.component.scss'],
})
export class BasicOverviewElementComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() public animation;
  @ViewChild('anchor', { read: ViewContainerRef, static: false })
  public viewContainerRef;
  private componentRef;
  private instance;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private livechartsService: LivechartsService,
  ) {}
  ngOnInit() {}
  ngAfterViewInit() {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.animation['component'],
    );
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    this.instance = this.componentRef.instance;
    this.instance.parameters = this.animation['params'];
    this.instance.scalable = this.livechartsService.settings.overview.scalable;
    this.instance.showHideSlices(
      this.livechartsService.settings.overview.slices,
    );
    this.changeDetectorRef.detectChanges(); // Necessary for OnInit to fire
  }
  ngOnDestroy() {
    // this.instance.kill();
  }
  public navigateToLivechart() {
    this.router.navigate([
      `/${this.livechartsService.settings.paths.editor}/`,
      this.animation.id,
    ]);
  }
}
