import { StandardControlComponent } from './standard-control/standard-control.component';
import { StandardNumberComponent } from './standard-number/standard-number.component';
import { StandardCheckboxComponent } from './standard-checkbox/standard-checkbox.component';
import { StandardSelectComponent } from './standard-select/standard-select.component';
import { StandardTextareaComponent } from './standard-textarea/standard-textarea.component';
import { StandardPhotoComponent } from './standard-photo/standard-photo.component';
import { StandardSlideComponent } from './standard-slide/standard-slide.component';
import { StandardSliderComponent } from './standard-slider/standard-slider.component';

export let controls = [
  {
    name: 'Standard control',
    id: 'standard-control',
    component: StandardControlComponent,
  },
  {
    name: 'Standard number',
    id: 'standard-number',
    component: StandardNumberComponent,
  },
  {
    name: 'Standard checkbox',
    id: 'standard-checkbox',
    component: StandardCheckboxComponent,
  },
  {
    name: 'Standard select',
    id: 'standard-select',
    component: StandardSelectComponent,
  },
  {
    name: 'Standard textarea',
    id: 'standard-textarea',
    component: StandardTextareaComponent,
  },
  {
    name: 'Standard photo',
    id: 'standard-photo',
    component: StandardPhotoComponent,
  },
  {
    name: 'Standard slide',
    id: 'standard-slide',
    component: StandardSlideComponent,
  },
  {
    name: 'Standard slider',
    id: 'standard-slider',
    component: StandardSliderComponent,
  },
];
