// import { NgModule } from '@angular/core';
import { NgModule, ModuleWithProviders, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestComponent } from './test/test.component';

const components = [TestComponent];

@NgModule({
  declarations: components,
  entryComponents: components,
  exports: components,
  // schemas: [NO_ERRORS_SCHEMA],
})
export class AnimationsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AnimationsModule,
      // providers: [],
    };
  }
}
