import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  OnDestroy,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { RenderVideoDialogComponent } from './render-video-dialog/render-video-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { LivechartsService } from '../../livecharts.service';
import { UtilService } from '../../services/util.service';
import { RenderService } from '../../services/render.service';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { LabelForStill } from '../../types/types';

@Component({
  selector: 'lib-basic-editor',
  templateUrl: './basic-editor.component.html',
  styleUrls: ['./basic-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  private animationID: string;
  private animation: any;
  public params: any;
  @ViewChild('livechart', { read: ViewContainerRef, static: false })
  public livechartContainerRef;
  // If you want to render to an actual canvas on the page, go get it like below
  // @ViewChild('canvas', { read: ViewContainerRef, static: false })
  // public canvasContainerRef;
  private componentRef;
  private instance;
  public labelsForStills: LabelForStill[];
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private livechartsService: LivechartsService,
    private utilService: UtilService,
    private renderService: RenderService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.livechartsService.setPlaybackCommand(
      this.livechartsService.settings.editor.playing,
    );
    this.route.params.subscribe(params => {
      this.animationID = params['animationID'];
      this.animation = this.livechartsService.getAnimationById(
        this.animationID,
      );
    });
  }
  ngAfterViewInit() {
    // console.log(this.canvasContainerRef.element.nativeElement);
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.animation['component'],
    );
    this.livechartContainerRef.clear();
    this.componentRef = this.livechartContainerRef.createComponent(
      componentFactory,
    );
    this.instance = this.componentRef.instance;
    // Set parameters on the instance of the Livechart
    this.instance.parameters = this.animation['params'];
    // Set the default value from the settings on the
    this.instance.scalable = this.livechartsService.settings.editor.scalable;
    this.instance.showSlices = this.livechartsService.settings.editor.slices;
    // this.instance.showHideSlices(this.livechartsService.settings.editor.slices);
    // Set parameters on the controls component
    this.params = this.animation['params'];
    // Because bindings have been changed in the CD loop
    // manually kick-off CD again
    this.changeDetectorRef.detectChanges(); // Necessary for OnInit on the instance to fire
    this.labelsForStills = this.instance.findLabelsForStills() || []; // Get the positions where stills can be taken
    this.changeDetectorRef.detectChanges(); // As these labels need to be represented in the view, run CD again
  }
  public ngOnDestroy() {
    this.instance.kill();
    this.changeDetectorRef.detach();
  }
  public getCategory() {
    let category = _.get(this.animation, ['params', 'metadata', 'category']);
    return category;
  }
  public getMetadata() {
    let metadata = _.get(this.animation, ['params', 'metadata']);
    return metadata;
  }
  public renderOfType(type) {
    // console.log(type);
    let promise;
    switch (type) {
      case 'png':
        this.instance.beforeRender();
        // promise = this.renderService.renderPNGToBlob(
        //   this.instance.svgElement,
        //   this.instance.getParameterValue('size')[0],
        //   this.instance.getParameterValue('size')[1],
        //   null, // this.canvasContainerRef.element.nativeElement
        // );
        promise = this.renderService.renderPNGToBlob(
          this.instance.svgElement,
          this.instance.getParameterValue('size')[0],
          this.instance.getParameterValue('size')[1],
          // 'blob',
          null, // this.canvasContainerRef.element.nativeElement
        );
        promise.then(blob => {
          saveAs(blob, `${this.animationID}.png`);
          this.instance.afterRender();
        });
        break;
      case 'svg':
        // let p = this.utilService.test();
        break;
      case 'video':
        this.showRenderVideoDialog();
        break;
      case 'pngs':
        this.instance.beforeRender();
        promise = this.renderService.renderSlicesToBlobs(
          this.instance.svgElement,
          this.instance.getParameterValue('width'),
          this.instance.getParameterValue('height'),
          this.instance.getParameterValue('size')[0],
          this.instance.getParameterValue('size')[1],
          this.instance.parameters.metadata.frame,
          this.instance.parameters.metadata.slices,
          null, // this.canvasContainerRef.element.nativeElement
        );
        promise.then(list => {
          // saveAs(list[0], `${this.animationID}.png`);
          let zip = new JSZip();
          list.map((img, i) => {
            zip.file(`${this.animationID}-slice-${i}.png`, img);
          });
          zip.generateAsync({ type: 'blob' }).then(zipBlob => {
            saveAs(zipBlob, `${this.animationID}-slices.zip`);
          });
          this.instance.afterRender();
        });
        break;
    }
  }
  public showRenderVideoDialog() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      instance: this.instance,
      // canvas: this.canvasContainerRef.element.nativeElement,
    };
    this.dialog.open(RenderVideoDialogComponent, dialogConfig);
  }
}
