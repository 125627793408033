import { Component } from '@angular/core';
import { Router } from '@angular/router';
// TOGGLE_LCS_HERE
// import { LivechartsService } from 'livecharts';
import { LivechartsService } from '../../../livecharts/src/lib/livecharts.service';
import { animations } from './animations/animations';
import { settings } from './settings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'Livecharts';
  constructor(
    private livechartsService: LivechartsService,
    private router: Router,
  ) {
    this.livechartsService.setAnimations(animations);
    this.livechartsService.setSettings(settings);
  }
  public home() {
    this.router.navigate(['/']);
  }
}
