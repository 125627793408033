/// <reference types="@types/dom-mediacapture-record" />
// TODO: there must be a better way to reference the types for the MediaRecorder, figure it out

import { BehaviorSubject } from 'rxjs';

export class VideoRecorder {
  private frames;
  private context: CanvasRenderingContext2D;
  private currentFrame: number;
  private startTime: number;
  private elapsedTime: number;
  private duration: number; // In milliseconds
  private recorder: MediaRecorder;
  private recorderData: Blob[] = [];
  private previousProgress: number;
  private timelineWasPaused: boolean;
  private timeOutReference;
  private aborted = false;
  private currentFramePromise: Promise<HTMLImageElement>;
  private progressSubject = new BehaviorSubject(null);
  public progressObservable = this.progressSubject.asObservable();
  public log = {
    elapsedAverage: 0,
    elapsedList: [],
    elapsedTotal: 0,
    endTime: null,
    frames: 0,
    lastFrame: null,
    logFrame(frameNr) {
      let now = performance.now();
      this.frames++;
      if (!this.startTime) {
        this.startTime = now;
      }
      this.elapsedTotal = now - this.startTime;
      if (this.previousTime) {
        let elapsed = now - this.previousTime;
        this.elapsedList.push(elapsed);
        this.elapsedAverage = this.elapsedTotal / this.frames;
      }
      if (this.lastFrame !== null && frameNr - this.lastFrame > 1) {
        this.skippedFrames += frameNr - this.lastFrame - 1;
      }
      this.lastFrame = frameNr;
      this.previousTime = now;
    },
    log() {
      console.log(`${this.frames} frames rendered`);
      console.log(`${this.skippedFrames} frames skipped`);
      console.log(`${this.elapsedTotal.toFixed(0)}ms total`);
    },
    logObject() {
      return {
        elapsedAverage: this.elapsedAverage,
        elapsedList: this.elapsedList,
        elapsedTotal: this.elapsedTotal,
        endTime: this.endTime,
        frames: this.frames,
        skippedFrames: this.skippedFrames,
        startTime: this.startTime,
      };
    },
    previousTime: null,
    skippedFrames: 0,
    startTime: null,
    stop() {
      this.endTime = performance.now();
      this.elapsedTotal = this.endTime - this.startTime;
    },
  };

  constructor(
    private svgElement: Element,
    private timeline: any,
    private frameRate: number,
    private width?: number,
    private height?: number,
    private canvas?: HTMLCanvasElement,
    private backgroundColor?: string,
  ) {
    // Store the state of the timeline
    this.previousProgress = timeline.progress();
    this.timelineWasPaused = timeline.paused();
    // Pause the timeline
    timeline.paused(true);
    // Calculate the nr of frames to be rendered
    this.frames = Math.floor(timeline.duration() * frameRate);
    this.duration = timeline.duration() * 1000;
    // Create the canvas from which to record
    if (!this.canvas) {
      this.canvas = document.createElement('canvas');
      this.canvas.width = this.width;
      this.canvas.height = this.height;
    } else {
      this.canvas.width = width;
      this.canvas.height = height;
    }
    this.context = this.canvas.getContext('2d');
    // console.log(`frameRate: ${frameRate}`);
    // console.log(`frames: ${this.frames}`);
    // console.log(`duration: ${this.duration}`);
  }
  public record(bps?: number): Promise<Blob> {
    let drawBackgroundColor = () => {
      // console.log(this.backgroundColor);
      if (this.backgroundColor) {
        this.context.fillStyle = this.backgroundColor;
        this.context.fillRect(0, 0, this.width, this.height);
      }
    };
    let drawFrameToCanvas = (frame: number): Promise<HTMLImageElement> => {
      this.currentFramePromise = new Promise<HTMLImageElement>(
        (resolve, reject) => {
          this.log.logFrame(frame);
          this.currentFrame = frame;
          let progressForFrame = frame / this.frames;
          this.timeline.progress(progressForFrame);
          let xml = new XMLSerializer().serializeToString(this.svgElement);
          let blob = new Blob([xml], { type: 'image/svg+xml' });
          let img = new Image();
          img.crossOrigin = 'Anonymous';
          img.onload = () => {
            drawBackgroundColor();
            this.context.drawImage(
              img,
              0,
              0,
              this.context.canvas.width,
              this.context.canvas.height,
            );
            this.progressSubject.next(progressForFrame);
            resolve(img);
          };
          img.onerror = () => {
            reject(new Error(`Failed to load image for frame ${frame}`));
          };
          img.src = URL.createObjectURL(blob);
        },
      );
      return this.currentFramePromise;
    };
    let nextFrame = () => {
      let now = performance.now();
      this.elapsedTime = now - this.startTime;
      let progress = this.elapsedTime / this.duration;
      let frame = Math.ceil(this.frames * progress);
      if (this.currentFrame === frame || progress === 0) {
        this.timeOutReference = setTimeout(() => nextFrame(), 5);
      } else if (this.elapsedTime > this.duration) {
        drawFrameToCanvas(this.frames).then(() => {
          this.timeOutReference = setTimeout(() => {
            this.recorder.stop();
            this.log.stop();
            // this.log.log();
            // console.log(this.log.logObject());
          }, 5);
        });
      } else if (this.currentFrame !== null && this.currentFrame < frame) {
        if (!this.aborted) {
          drawFrameToCanvas(frame).then(() => {
            nextFrame();
          });
        } else {
          this.recorder.stop();
          this.log.stop();
        }
      }
    };
    let promise = drawFrameToCanvas(1).then(img => {
      return new Promise<Blob>((resolve, reject) => {
        // console.log(this.frameRate);
        // @ts-ignore
        let stream = this.canvas.captureStream(this.frameRate);
        let recorderOptions = { mimeType: 'video/webm' };
        if (bps) {
          recorderOptions['videoBitsPerSecond'] = bps;
        }
        this.recorder = new MediaRecorder(stream, recorderOptions);
        this.recorder.ondataavailable = event => {
          if (event.data && event.data.size) {
            this.recorderData.push(event.data);
          }
        };
        this.recorder.onstop = () => {
          let blob = new Blob(this.recorderData, { type: 'video/webm' });
          this.timeline.paused(this.timelineWasPaused);
          this.timeline.progress(this.previousProgress);
          resolve(blob);
        };
        this.startTime = performance.now();
        this.recorder.start();
        nextFrame();
      });
    });
    return promise;
  }
  public recordFramesToCache(bps?: number) {
    // This is the method being used at the moment, first all images are cached
    // only then are they recorded from the canvas
    let drawBackgroundColor = () => {
      // Drawing a transparent PNG to the canvas results in a non-transparent
      // video with a black background and a buggy looking animation,
      // set a background color in the metadata of the parameters to be drawn with this method.
      if (this.backgroundColor) {
        this.context.fillStyle = this.backgroundColor;
        this.context.fillRect(0, 0, this.width, this.height);
      }
    };
    return new Promise<HTMLImageElement[]>((resolve, reject) => {
      let images = [];
      let currentFrame = 1;
      let nextFrameToImage = () => {
        if (this.aborted) {
          resolve(null);
        } else if (currentFrame <= this.frames) {
          let progress = currentFrame / this.frames;
          this.timeline.progress(progress);
          let xml = new XMLSerializer().serializeToString(this.svgElement);
          let blob = new Blob([xml], { type: 'image/svg+xml' });
          let img = new Image();
          img.crossOrigin = 'Anonymous';
          img.onload = () => {
            images.push(img);
            let p = currentFrame / (this.frames * 2);
            this.progressSubject.next(p);
            if (!this.aborted) {
              nextFrameToImage();
            }
          };
          img.src = URL.createObjectURL(blob);
          currentFrame++;
        } else {
          resolve(images);
        }
      };
      nextFrameToImage();
    }).then(images => {
      if (!images) {
        return;
      } else {
        return new Promise<Blob>((resolve, reject) => {
          let currentFrame = 1;
          let nextFrame = () => {
            let now = performance.now();
            this.elapsedTime = now - this.startTime;
            let progress = this.elapsedTime / this.duration;
            let frame = Math.ceil(this.frames * progress);
            // console.log(`elapsed: ${this.elapsedTime}, frame: ${frame}, frame < frames ${frame < this.frames}`);
            if (currentFrame === frame || progress === 0) {
              this.timeOutReference = setTimeout(() => nextFrame(), 5);
            } else if (this.elapsedTime > this.duration) {
              this.log.logFrame(frame);
              drawBackgroundColor();
              let img = images[this.frames - 1];
              this.context.drawImage(
                img,
                0,
                0,
                this.context.canvas.width,
                this.context.canvas.height,
              );
              this.recorder.stop();
              this.log.stop();
              this.log.log();
              // console.log(this.log.logObject());
            } else if (currentFrame !== null && currentFrame < frame) {
              this.log.logFrame(frame);
              drawBackgroundColor();
              let img = images[frame - 1];
              this.context.drawImage(
                img,
                0,
                0,
                this.context.canvas.width,
                this.context.canvas.height,
              );
              currentFrame = frame;
              this.progressSubject.next(0.5 + frame / (this.frames * 2));
              this.timeOutReference = setTimeout(() => nextFrame(), 5);
            }
          };
          drawBackgroundColor();
          this.context.drawImage(
            images[0],
            0,
            0,
            this.context.canvas.width,
            this.context.canvas.height,
          );
          // console.log(this.frameRate);
          // @ts-ignore
          let stream = this.canvas.captureStream(this.frameRate);
          let recorderOptions = { mimeType: 'video/webm' };
          if (bps) {
            recorderOptions['videoBitsPerSecond'] = bps;
          }
          this.recorder = new MediaRecorder(stream, recorderOptions);
          this.recorder.ondataavailable = event => {
            if (event.data && event.data.size) {
              this.recorderData.push(event.data);
            }
          };
          this.recorder.onstop = () => {
            let blob = new Blob(this.recorderData, { type: 'video/webm' });
            this.timeline.paused(this.timelineWasPaused);
            this.timeline.progress(this.previousProgress);
            resolve(blob);
          };
          this.startTime = performance.now();
          this.recorder.start();
          nextFrame();
          // images.forEach((image) => document.body.appendChild(image));
        });
      }
    });
  }
  public abort() {
    this.aborted = true;
    if (this.timeOutReference) {
      clearTimeout(this.timeOutReference);
    }
    return;
  }
}
