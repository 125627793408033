import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @Input() public metadata: any;
  public hasSlices: boolean;
  constructor() {}
  public ngOnInit() {
    this.hasSlices =
      this.metadata['outputTypes'].find(type => type === 'pngs') &&
      this.metadata['frame'] &&
      this.metadata['slices'];
  }
}
