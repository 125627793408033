import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { BasicOverviewModule } from './overview/basic-overview/basic-overview.module';
import { BasicEditorModule } from './editor/basic-editor/basic-editor.module';
// Services
import { LivechartsService } from './livecharts.service';
import { UtilService } from './services/util.service';
import { RenderService } from './services/render.service';
// Components
import { LivechartBaseComponent } from './livechart/livechart.component';

@NgModule({
  declarations: [LivechartBaseComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    BasicOverviewModule,
    BasicEditorModule,
  ],
  exports: [LivechartBaseComponent, BasicOverviewModule, BasicEditorModule],
  providers: [LivechartsService, UtilService, RenderService],
})
export class LivechartsModule {}
