import { Component, ViewEncapsulation } from '@angular/core';
import { LivechartBaseComponent } from '../../../../../livecharts/src/lib/livechart/livechart.component';
import { LivechartsService } from '../../../../../livecharts/src/lib/livecharts.service';
import { UtilService } from '../../../../../livecharts/src/lib/services/util.service';
// import { RenderService } from '../../../../../livecharts/src/lib/services/render.service';
import { SvgService } from '../../../../../livecharts/src/lib/services/svg.service';
// import { LivechartsService, UtilService, RenderService, SvgService } from 'livecharts';
// import * as d3 from 'd3';
import { gsap } from 'gsap';
let g: any, s: any;


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TestComponent extends LivechartBaseComponent {
  public yLogo: number;
  public xLogo: number;
  public blurme = '#noblur';
  constructor(
    public livechartsService: LivechartsService,
    public utilService: UtilService,
    public svgService: SvgService,
  ) {
    super(livechartsService, utilService, svgService);
    g = (id: string) => this.values[id]; // Method to succinctly dig-up a parameter
    s = (id: string, value: any) => {
      // Same but for setting a parameter value
      this.values[id] = value;
    };
  }
  public initializeLivechart() {
    super.initializeLivechart();
  }
  public createElements() {
    super.createElements();
    let contentGroup = this.svgSelection.select(`#content-${this.id}`);
    contentGroup.selectAll('*').remove();
    // Draw background image
    let backgroundGroup = this.svgSelection.select(`#background-${this.id}`);
    backgroundGroup.select(`#backgroundblur-${this.id}`);
    backgroundGroup.selectAll('*').remove();
    let patternGroup = this.svgSelection.select(`#pattern-${this.id}`);
    patternGroup.selectAll('*').remove();
    let box = contentGroup.node().getBBox();
    let photoOptions = { // vraag aan jorma: ik heb deze options nu een aparte naam gegeven of overschirjf je die net als box?
      selection: patternGroup,
      id: this.id,
      url: g('photo'),
      label: 'main-foto', // Vraag aan Jorma: waarom niet label uit parameter
      x: 0,
      y: 0,
      width: 1080,
      height: 1080,
    };
    if (g('photo')) {
      this.drawImageInPattern(patternGroup, photoOptions, true);
      this.drawBackgroundImage(backgroundGroup);
    } else {
      let rect = backgroundGroup.append('rect')
        .attr('id', `backgroundLogo-${this.id}`)
        .attr('x', 0)
        .attr('y', 0)
        .style('opacity', 0)
        .attr('width', g('width'))
        .attr('height', g('height'))
        .attr('fill', `#ffffff`);
    }
    let rectangleGroup = contentGroup.append('g');
    let textGroup = contentGroup.append('g');
    let textSubGroup = textGroup.append('g');
    let styles = {
      normal: {
        fill: g('textColor'),
        'font-size': 40,
        'font-weight': '400',
        'text-anchor': g('textAnchor'),
        'font-family': g('fontFamily'),
      },
      heading: {},
      star: {},
      dollar: {},
      hash: {},
      underscore: {},
    };
    let widthTextRect = g('widthRect') - (g('rectangleMarginSide'));
    console.log(widthTextRect);
    let xValueText = g('textAnchor') === 'middle' ? g('widthRect') / 2 : g('textAnchor') === 'start' ? g('rectangleMarginSide') : g('widthRect') - g('rectangleMarginSide')

    // To do: align right
    let options = {
      selection: textSubGroup,
      id: this.id,
      text: g('text'),
      label: 'main-text',
      x: xValueText,
      y: 200,
      width:  widthTextRect,
      styles,
    };
    this.svgService.drawText(options);
    let firstText = textSubGroup.select('text:first-child');
    box = firstText.node().getBBox();
    let deltaX = 30;
    let deltaY = 20;
    // textSubGroup
    //   .append('g')
    //   .attr('transform', `rotate(180 ${box.x - deltaX} ${box.y - deltaY})`)
    //   .append('text')
    //   .attr('x', box.x - deltaX)
    //   .attr('y', box.y - deltaY)
    //   .style('fill', '#FFF')
    //   .style('text-anchor', 'end')
    //   .style('letter-spacing', 3)
    //   .style('font-size', 80)
    //   .style('font-weight', 500)
    //   .style('font-style', 'italic')
    //   .style('font-family', 'Open Sans')
    //   .style('dominant-baseline', 'middle')
    //   .text("''");
    // let lastText = textSubGroup.select('text:last-child');
    // Position text
    box = contentGroup.node().getBBox();
    console.log(box.width);
    textSubGroup.attr(
      'transform',
      `translate(0 ${g('height') -
        box.y -
        box.height -
        g('distanceFromBottom')})`,
    );
    box = textGroup.node().getBBox();
    // Do some math
    let boxHeight = box.height + 2 * g('rectangleMargin');
    let boxWidth = box.width;
    let rectHeight;
    if (g('rectangleAngle') === 0) {
      rectHeight = boxHeight;
    } else {
      let a =
        boxHeight / 2 / Math.tan(Math.abs(g('rectangleAngle') * Math.PI) / 180);
      let a1 = a + boxWidth / 2;
      let b1 = ((boxHeight / 2) * a1) / a;
      rectHeight =
        2 * Math.cos(Math.abs(g('rectangleAngle') * Math.PI) / 180) * b1;
    }
    // console.log(`rectHeight: ${rectHeight}`);
    // Draw rectangle
    let yTopOfRectangle = box.y + box.height / 2 - rectHeight / 2;
    this.yLogo = yTopOfRectangle - 190 / 1.2;
    // let rectangel fill whole width from livechart
    let rectFill =  g('widthRect') === g('width') ? 200 : 0;
    console.log(('widthRect'));
    console.log(rectFill);
    rectangleGroup
      .append('rect')
      .attr('x', -rectFill)
      .attr('y', yTopOfRectangle)
      .attr('width',  g('widthRect') + 2 * rectFill)
      .attr('height', rectHeight)
      .style('fill', g('rectangleColor'));
    rectangleGroup.attr(
      'transform',
      `rotate(${g('rectangleAngle')} ${g('width') / 2} ${box.y +
        box.height / 2})`,
    );
  }
  public drawImageInPattern(selection, imageData, embedImage: boolean) {
    let g = (k): any => this.values[k];

    let id = `pattern-${this.id}`;
    let url = imageData['url'];
    let scaleX = g('width') / imageData['width'];
    let scaleY = g('height') / imageData['height'];
    let scale = Math.max(scaleX, scaleY);
    let factor = (g('scaleBg') || 1) * scale;
    // Center the image horizontally
    let tlX = ((g('width') - factor * imageData['width']) / 2) + (g('translateBgX') || 0);
    // Avoid moving the image off the screen
    tlX = Math.min(0, tlX);
    tlX = Math.max(-factor * imageData['width'] + g('width'), tlX);
    // Center the image vertically
    let tlY = ((g('height') - factor * imageData['height']) / 2) + (g('translateBgY') || 0);
    // Avoid moving the image off the screen
    tlY = Math.min(0, tlY);
    tlY = Math.max(-factor * imageData['height'] + g('height'), tlY)

    let image = selection.append('image')
      .attr('xlink:href', url)
      .attr('x', tlX)
      .attr('y', tlY)
      .attr('width', factor * imageData['width'])
      .attr('height', factor * imageData['height']);
  }

  public drawBackgroundImage(selection) {
    let g = (k): any => this.values[k];

    let rect = selection.append('rect')
      .attr('id', `image-${this.id}`)
      .attr('x', 0)
      .attr('y', 0)
      .style('opacity', 1)
      .attr('width', g('width'))
      .attr('height', g('height'))
      .attr('fill', `url(#pattern-${this.id})`);
  }
  public createAnimation() {
    super.createAnimation();
    // let serializer = new XMLSerializer();
    // let str = serializer.serializeToString(this.svgSelection.node());
    this.xLogo = -190 / 2 + Number(g('width')) / 2;
    if (g('animationMic') === 'slideFromTop') {
      this.animation.set({}, {}, '+=2');
      this.animation.set({}, {}, 'positionForStill-1');
      this.animation.fromTo(`#mic-${this.id}`, 1, { attr: { x: this.xLogo , y: -200} }, { attr: { y: this.yLogo, x : this.xLogo } });
      this.animation.set({}, {}, 'positionForStill-2');
      this.animation.set({}, {}, '+=2');
    }
    if (g('animationMic') === 'slideFromLeft') {
      this.animation.set({}, {}, '+=2');
      this.animation.set({}, {}, 'positionForStill-1');
      this.animation.fromTo(`#mic-${this.id}`, 1, { attr: { x: - 200 , y: this.yLogo} }, { attr: { y: this.yLogo, x : this.xLogo } });
      this.animation.set({}, {}, 'positionForStill-2');
      this.animation.set({}, {}, '+=2');
    }
    if (g('animationMic') === 'bounceFromTop') {
      let bounces  = 5;
      let height   = -400;
      let time     = 0.5;

      let yDecay = height / bounces;
      let tDecay = time   / bounces;

     // let timeline = new TimelineMax({ repeat: -1, repeatDelay: 2 });
     //  this.animation.from(`#mic-${this.id}` , time, { attr: { x: this.xLogo , y: -this.yLogo - 200} })
     // this.animation.to(`#mic-${this.id}` , time, { ease: 'sine.out', y: 0 });
      this.animation.fromTo(`#micGroup-${this.id}` , time, { y: -this.yLogo - 200, opacity: 0}, { ease: 'sine.out', y: 0, opacity: 1 });

      for (let i = 0; i < bounces; i++) {

        this.animation.to(`#micGroup-${this.id}`, time -= tDecay, {
          y: height -= yDecay,
          ease: 'sine.in',
          repeat: 1,
          yoyo: true
        });
      }
      this.animation.set({}, {}, '+=2');
    }
    if (g('animationMic') === 'none') {
      this.animation.set({}, {}, '+=5');
    }
    if (true) {
      this.animation.set({}, {}, '+=2');
      this.animation.to(`#micGroup-${this.id}` , {opacity: 0});
      this.animation.to(`#content-${this.id}` , {opacity: 0});
      this.animation.to(`#image-${this.id}` , {opacity: 0});
      if (g('backgroundLogo') !== 'none') {
        this.animation.to(`#backgroundLogo-${this.id}`, { opacity: 1, fill: g('backgroundLogo') });
      }
      this.animation.fromTo(`#logo-${this.id}`, 2.5 , {opacity: 0}, {opacity: 1});
      // this.animation.to(`#background-${this.id}` , {filter: 'url(#blur)'}, 'clear');
    }
    if (false) {
      this.animation.set({}, {}, '+=2');
      this.animation.to(`#micGroup-${this.id}` , {opacity: 0}, 'clear');
      this.animation.to(`#content-${this.id}` , {opacity: 0}, 'clear');
      this.animation.to(`#background-${this.id}` , {opacity: 0}, 'clear');
      this.animation.to(`#logoIcon1-${this.id}` , {opacity: 1}, 'showLogoIcon');
      this.animation.to(`#logoIcon2-${this.id}` , {opacity: 1}, 'showLogoIcon');
      this.animation.to(`#logoIcon3-${this.id}` , {opacity: 1}, 'showLogoIcon');
      // this.animation.fromTo(`#micGroup-${this.id}` , {opacity: 0}, 'clear');
      // this.animation.to(`#logoIcon-${this.id}`, {duration: 2.5 , scale: 2, ease: 'expoScale(1, 2)'});
    }
  }
}
