import { Component, OnInit, OnDestroy } from '@angular/core';
import { LivechartsService } from '../../../livecharts.service';
import * as d3 from 'd3';
import { Progress } from '../../../types/types';

// TODO: make the thumb draggable

@Component({
  selector: 'lib-scrub',
  templateUrl: './scrub.component.html',
  styleUrls: ['./scrub.component.scss'],
})
export class ScrubComponent implements OnInit, OnDestroy {
  public progress: Progress;
  public done: d3.Selection<HTMLElement, any, any, any>;
  public todo: d3.Selection<HTMLElement, any, any, any>;
  public thumb: d3.Selection<HTMLElement, any, any, any>;
  public container: d3.Selection<HTMLElement, any, any, any>;
  public spacer: d3.Selection<HTMLElement, any, any, any>;
  private progressSubscription;
  constructor(private livechartsService: LivechartsService) {}

  ngOnInit() {
    // Dig up the elements that we need the sizes of
    // using D3... I know it would be cleaner to use the ComponentRef
    // but who cares?
    this.done = d3.select('#lcs-progress-scrubber-done');
    this.container = d3.select('#lcs-progress-thumb-container');
    this.spacer = d3.select('#lcs-progress-thumb-spacer');
    // When new progress values come in, set the scrubber to the correct position
    this.progressSubscription = this.livechartsService.getProgressObservable.subscribe(
      progress => {
        // this.value = progress.progress;
        this.progress = progress;
        this.setScrubberToProgress();
      },
    );
  }
  ngOnDestroy() {
    this.progressSubscription.unsubscribe();
  }
  private setScrubberToProgress() {
    // Please note that the 20px is hardcoded here and in the SCSS file
    this.done.style(
      'flex-basis',
      `calc(${this.progress.progress * 100}% - 20px)`,
    );
    this.spacer.style(
      'flex-basis',
      `calc(${this.progress.progress * 100}% - 20px)`,
    );
  }
  public clickScrubber($event) {
    // When the user clicks the scrubber, figure out the progress and set it
    // NB - the complete width of the scrubber is used without taking into
    // account the width of the thumb
    let containerRect = this.container.node().getBoundingClientRect();
    let progress = $event.offsetX / containerRect.width;
    this.livechartsService.setProgress(progress);
  }
}
