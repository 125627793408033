import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { LivechartsService } from '../../../livecharts.service';
import { Progress } from '../../../types/types';

@Component({
  selector: 'lib-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
  public progress$: Observable<Progress>;
  public progress: number;
  public time: number;
  public duration: number;
  private progressSubscription: any;
  constructor(
    private livechartsService: LivechartsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.progress$ = this.livechartsService.getProgressObservable;
    this.progressSubscription = this.livechartsService.getProgressObservable.subscribe(
      progress => {
        this.progress = progress.progress;
        this.time = progress.time;
        this.duration = progress.duration;
        this.changeDetectorRef.detectChanges();
      },
    );
  }
  ngOnDestroy() {
    this.progressSubscription.unsubscribe();
  }
}
