import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LivechartsService } from '../../../../livecharts.service';

@Component({
  selector: 'lib-slices',
  templateUrl: './slices.component.html',
  styleUrls: ['./slices.component.scss'],
})
export class SlicesComponent implements OnInit, OnDestroy {
  private formValueSubscription;
  public formControl = new FormControl();
  constructor(private livechartsService: LivechartsService) {}

  ngOnInit() {
    this.formControl.setValue(this.livechartsService.settings.editor.slices);
    this.formValueSubscription = this.formControl.valueChanges.subscribe(
      value => {
        this.formValueChanged(value);
      },
    );
  }
  ngOnDestroy() {
    this.formValueSubscription.unsubscribe();
  }
  public formValueChanged(value) {
    // console.log(value);
    this.livechartsService.setShowSlices(value);
  }
}
