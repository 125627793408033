import { Component, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Form } from '@angular/forms';
import { Validators } from '@angular/forms';
import { LivechartsService } from '../../../../livecharts.service';

// Base class for all other controls
// Sets up wiring with LivechartsService
export class ControlComponent implements OnInit, OnDestroy {
  @Input() public id: string;
  @Input() public control: string;
  @Input() public settings: any;
  public description: string;
  public errorMessage: string;
  public errorMessages: string[];
  public form = new FormControl();
  private formValueSubscription;
  private resetParameterSubscription;
  private setParameterSubscription;

  constructor(public livechartsService: LivechartsService) {}

  ngOnInit() {
    // Create and set validations on formControl
    this.setValidations();
    // Initialize control with default value
    this.setDefault();
    this.description = this.settings['description'];
    this.formValueSubscription = this.form.valueChanges.subscribe(value => {
      this.formValueChanged();
    });
    // Subscribe to observable that tells the control to reset to the default value
    this.resetParameterSubscription = this.livechartsService.resetParameterObservable.subscribe(
      () => {
        this.reset();
      },
    );
    // Subscribe to the observable that emits saved data when it needs to be loaded
    this.setParameterSubscription = this.livechartsService.setParameterObservable.subscribe(
      parameterObject => {
        this.setNewValue(parameterObject);
      },
    );
  }
  ngOnDestroy() {
    this.formValueSubscription.unsubscribe();
    this.resetParameterSubscription.unsubscribe();
    this.setParameterSubscription.unsubscribe();
  }
  public setDefault() {
    // Overwrite this message when the form does
    // not actually get the value assigned to it (i.e. select)
    this.form.setValue(this.settings['default']);
  }
  public reset() {
    this.setDefault();
    this.pushNewValue();
  }
  public getValue() {
    // Overwrite this method if the value cannot just be
    // taken from the form (like in a select for example)
    return this.form.value;
  }
  public formValueChanged() {
    if (this.form.valid) {
      this.errorMessage = null;
      this.pushNewValue();
    } else {
      // console.log(this.form.errors);
      this.errorMessages = this.getErrorMessages(this.form.errors);
      this.errorMessage = this.errorMessages[0];
    }
  }
  public getErrorMessages(errorObject: any) {
    let messages: string[] = [];
    let keys = Object.keys(errorObject);
    for (let key of keys) {
      let msg = this.getErrorMessage(key);
      messages.push(msg);
    }
    return messages;
  }
  public getErrorMessage(key: string) {
    let message: string;
    switch (key) {
      case 'required':
        message = `Required`;
        break;
      case 'min':
        message = `Minimum value is ${this.settings['min']}`;
        break;
      case 'max':
        message = `Maximum value is ${this.settings['max']}`;
        break;
      default:
        throw new Error(`No error message for key: ${key}`);
        break;
    }
    return message;
  }
  setOutputData(event?) {
    let value = this.getValue();
    let param = {
      id: this.id,
      value,
    };
    this.livechartsService.pushParameter(param);
  }
  public pushNewValue() {
    // Push new value to LivechartService
    let value = this.getValue();
    let parameter = {
      id: this.id,
      value, // Property shorthand
    };
    this.livechartsService.pushParameter(parameter);
  }
  public setNewValue(parameterObject) {
    // TODO: at some higher level check to see that the saved data is compatible
    // with the current version of the animation and show an error if it isn't
    let value = parameterObject[this.id];
    if (value) {
      this.form.setValue(value);
    } else {
      let errorMessage = `Could not find id ${this.id} in parameterObject ${parameterObject}`;
      throw new Error(errorMessage);
    }
  }
  public setValidations() {
    let validators = [];
    if (this.settings['required'] === true) {
      let v = Validators.required;
      validators.push(v);
    }
    if (this.settings['min'] !== undefined) {
      let v = Validators.min(this.settings['min']);
      validators.push(v);
    }
    if (this.settings['max'] !== undefined) {
      let v = Validators.max(this.settings['max']);
      validators.push(v);
    }
    // console.log(validators);
    this.form.setValidators(validators);
  }
}
