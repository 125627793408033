import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { FontDescriptor } from '../../lib/types/types';
import { LivechartsService } from '../livecharts.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SvgService {
  constructor(
    private livechartsService: LivechartsService,
    private http: HttpClient,
  ) {}
  public splitText(text: string, removeEmptyLines?: boolean) {
    let singleReturn = /\r\n|\n|\f/g;
    let lines = text.split(singleReturn);
    if (removeEmptyLines) {
      lines = _.filter(lines, l => (l ? true : false));
    }
    return lines;
  }
  public drawText({
    selection,
    id,
    text,
    label,
    x = 0,
    y = 0,
    width = 500,
    lineHeightFactor = 1.2,
    bulletChar = '•',
    bulletIndent = 0,
    styles = {
      normal: {
        fill: '#000',
        'font-size': 18,
        'font-weight': '400',
        'text-anchor': 'start',
      },
      heading: {
        // '1': { 'font-size': 36 },
        // '2': { 'font-size': 32 },
        // '3': { 'font-size': 28 },
        // '4': { 'font-size': 24 },
      },
      star: {},
      dollar: {},
      hash: {},
      underscore: {},
    },
  }) {
    let parseString = str => {
      // console.log(str);
      let acc = '';
      let remaining = str.trim();
      let list = [];
      let push = (type, nr) => {
        if (acc.length > 0) {
          list.push({ type: 'text', value: acc });
        }
        acc = '';
        list.push({ type });
        remaining = remaining.slice(nr);
      };
      while (remaining.length > 0) {
        if (remaining.slice(0, 1) === ' ') {
          push('space', 1);
        } else if (remaining.slice(0, 2) === '**') {
          push('star', 2);
        } else if (remaining.slice(0, 2) === '$$') {
          push('dollar', 2);
        } else if (remaining.slice(0, 2) === '##') {
          push('hash', 2);
        } else if (remaining.slice(0, 2) === '__') {
          push('underscore', 2);
        } else {
          acc = acc + remaining.slice(0, 1);
          remaining = remaining.slice(1);
        }
      }
      if (acc.length > 0) {
        // console.log(`acc: ${acc}, length: ${acc.length}`);
        list.push({ type: 'text', value: acc });
      }
      return list;
    };

    let renderBlocks = (selection, blocks) => {
      let state = {
        star: false,
        dollar: false,
        hash: false,
        underscore: false,
      };
      let lineY = y;
      // for (let block of blocks) {
      let index = 0;
      for (let i = 0; i < blocks.length; i++) {
        let block = blocks[i];
        // console.log(block);
        // console.log(i);
        // console.log(block.type);
        let fontSize;
        let textElement;
        let applyStyles = (element, styleObject) => {
          _.map(styleObject, (value, key) => {
            element.style(key, key === 'font-size' ? `${value}px` : value);
          });
        };
        let applyAllStyles = element => {
          if (block.type === 'paragraph' || block.type === 'bullet') {
            applyStyles(textElement, styles['normal']);
          } else if (block.type === 'heading') {
            let style = styles['heading'][block.level];
            applyStyles(textElement, styles['normal']);
            applyStyles(textElement, style);
          }
          _.map(Object.keys(state).sort(), key => {
            let value = state[key];
            if (value) {
              element.classed(key, true);
              applyStyles(element, styles[key]);
            }
          });
        };
        let stepLine = (color?) => {
          // selection.append('circle').attr('cx', x).attr('cy', lineY).attr('r', 2).style('fill', color || '#c00');
          lineY = lineY + lineHeightFactor * styles.normal['font-size'];
        };
        if (block.type === 'empty') {
          stepLine('#0c0');
        } else {
          textElement = selection
            .append('text')
            .attr('x', x)
            .attr('y', lineY)
            .attr('id', `text-line-${label}-${index}`)
            .attr('data-index', index)
            .classed(`text-line-${label}-${id}`, true);
          index++;
          let currentElement;
          let previousElement;
          let bulletStep: number;
          let justDrewBullet = false;
          if (block.type === 'bullet') {
            currentElement = textElement
              .append('tspan')
              .attr(
                'dx',
                styles.normal['text-anchor'] === 'start' ? bulletIndent : 0,
              )
              .text(`${bulletChar}`);
            applyAllStyles(currentElement);
            currentElement = textElement
              .append('tspan')
              .attr('opacity', 0)
              .text(`_`);
            applyAllStyles(currentElement);
            bulletStep = textElement.node().getBBox().width;
            // console.log(bulletStep);
          }
          for (let segment of block.value) {
            if (segment.type === 'text') {
              currentElement = textElement.append('tspan').text(segment.value);
              applyAllStyles(currentElement);
              if (previousElement) {
                let box = textElement.node().getBBox();
                if (
                  styles.normal['text-anchor'] === 'middle'
                    ? box.width > width
                    : box.x - x + box.width > width
                ) {
                  currentElement.remove();
                  stepLine('#00c');
                  textElement = selection
                    .append('text')
                    .attr('id', `text-line-${label}-${index}`)
                    .attr('data-index', index)
                    .classed(`text-line-${label}-${id}`, true)
                    // .attr('x', block.type === 'bullet' ? bulletStep + x : x)
                    .attr(
                      'x',
                      block.type === 'bullet' &&
                        styles.normal['text-anchor'] === 'start'
                        ? bulletStep + x
                        : x,
                    )
                    .attr('y', lineY);
                  index++;
                  currentElement = textElement
                    .append('tspan')
                    .text(segment.value);
                  applyAllStyles(currentElement);
                }
              }
            } else if (segment.type === 'space') {
              currentElement = textElement.append('tspan').text(' ');
              applyAllStyles(currentElement);
            } else if (segment.type === 'star') {
              state.star = !state.star;
            } else if (segment.type === 'dollar') {
              state.dollar = !state.dollar;
            } else if (segment.type === 'underscore') {
              state.underscore = !state.underscore;
            } else if (segment.type === 'hash') {
              state.hash = !state.hash;
            }
            previousElement = currentElement;
          }
        }
        if (block.type !== 'empty') {
          stepLine('#c00');
        }
      }
    };

    if (text) {
      let blocks = this.splitText(text);
      // console.log(blocks);
      blocks = _.map(blocks, block => {
        if (block === '') {
          return { type: 'empty', value: null };
        } else if (block.slice(0, 2) === '* ') {
          return { type: 'bullet', value: parseString(block.slice(2)) };
        } else if (block.slice(0, 2) === '# ') {
          return {
            type: 'heading',
            level: '1',
            value: parseString(block.slice(2)),
          };
        } else if (block.slice(0, 3) === '## ') {
          return {
            type: 'heading',
            level: '2',
            value: parseString(block.slice(3)),
          };
        } else if (block.slice(0, 4) === '### ') {
          return {
            type: 'heading',
            level: '3',
            value: parseString(block.slice(4)),
          };
        } else {
          return { type: 'paragraph', value: parseString(block) };
        }
      });
      // console.log(blocks);
      renderBlocks(selection, blocks);
    }
  }
  public generateEmbeddedFontCSS(fonts): Promise<string> {
    // Top level method to create a stylesheet with all the required font CSS
    // with the fonts base64 encoded in the CSS for the fonts in the parameters
    let promises: Promise<string>[] = [];
    for (let font of fonts) {
      promises.push(this.generateFontStylesheet(font));
    }
    let promise: Promise<string> = Promise.all(promises).then(stylesheets => {
      let stylesheetString = stylesheets.join('\n');
      return stylesheetString;
    });
    return promise;
  }
  public generateFontStylesheet(font) {
    // For each font in this animation, dig up the information from the settings and
    // generate the stylesheet (this is different depending on where the font
    // comes from).
    let stylesheetPromise: Promise<string>;
    let fontSettings = this.livechartsService.settings['fonts'];
    // Throw an error if the font information is not found
    let fontSetting = _.find(
      fontSettings,
      fS => fS.fontFamily === font.fontFamily,
    );
    if (!fontSetting) {
      throw new Error(`Font-family: ${font.fontFamily} not found in settings.`);
    }
    switch (fontSetting.type) {
      case 'google':
        // When it is a Google font call the appropriate method
        stylesheetPromise = this.generateGoogleFontCSS(font, fontSetting);
        break;
      case 'assets':
        // When it is a local font call the appropriate method
        stylesheetPromise = this.generateAssetFontCSS(font, fontSetting);
        break;
    }
    // Return the stylesheetPromise that was generated
    return stylesheetPromise;
  }
  public generateGoogleFontCSS(font, setting): Promise<string> {
    // console.log(font);
    // console.log(setting);
    let promise = this.http
      .get(setting.url, { responseType: 'text' }) // Get the stylesheet from Google Fonts
      .toPromise()
      .then(css => {
        // console.log(css);
        let fontRegex = /\/*.*?}\n?/gms; // Split the stylesheet into chunks per @font-face declaration
        let allFonts = css.match(fontRegex);
        // console.log(allFonts);
        let promises: Promise<string>[] = [];
        // Loop through all the fonts that should be embedded
        for (let stylesAndWeight of font.stylesAndWeights) {
          let regexString = `.*?font-family: '${font.fontFamily}';.*?font-style: ${stylesAndWeight.style};.*?font-weight: ${stylesAndWeight.weight};.*?}\n?`;
          let regex = new RegExp(regexString, 'gms');
          // Pull out only the fonts that we need
          let list = _.filter(allFonts, str => regex.test(str));
          // console.log(list);
          for (let fontFace of list) {
            // console.log(fontFace);
            let urlRegex = /url\((.*?)\)/gms;
            let match = urlRegex.exec(fontFace);
            let url = match[1];
            // console.log(match);
            promises.push(
              this.downloadAndEncodeWoff2(url).then(fontString => {
                // console.log(fontFace);
                // console.log(match);
                // console.log(url);
                // console.log(fontString.length);
                let replacementRegex = /url\(.*?\)/gms;
                let cssString = fontFace.replace(
                  replacementRegex,
                  `url('${fontString}')`,
                );
                // console.log(cssString);
                return cssString;
              }),
            );
          }
        }
        return Promise.all(promises).then(l => {
          let result = l.join('\n');
          return result;
        });
      });
    return promise;
  }
  public downloadAndEncodeWoff2(url: string) {
    // console.log(url);
    let promise = this.http
      .get(url, { responseType: 'blob' })
      .toPromise()
      .then(blob => {
        let promise2 = new Promise<string>(function(resolve, reject) {
          let reader = new FileReader();
          reader.onloadend = function() {
            // It seems Typescript doesn't infer automatically that readAsDataURL
            // returns a base64 encoded string...
            let base64data = reader.result as 'string';
            resolve(base64data);
          };
          reader.onerror = function(event) {
            reject(new Error(`Base64 encoding failed`));
          };
          reader.readAsDataURL(blob);
        });
        return promise2;
      });
    return promise;
  }
  public generateAssetFontCSS(font, setting): Promise<string> {
    // console.log('generateAssetFontCSS');
    let urlString = `assets/fonts/${setting.fileName}`;
    let promise = this.downloadAndEncodeWoff2(urlString).then(base64data => {
      let cssString = `@font-face {
        font-family: '${setting.fontFamily}';
        font-style: ${setting.fontStyle};
        font-weight: ${setting.fontWeight};
        font-display: swap;
        src: url('${base64data}');
      }`;
      return cssString;
    });
    return promise;
  }
}
