import { Component } from '@angular/core';
import { ControlComponent } from '../base/control.component';
import { LivechartsService } from '../../../../livecharts.service';

@Component({
  selector: 'lib-standard-number',
  templateUrl: './standard-number.component.html',
  styleUrls: ['./standard-number.component.scss'],
})
export class StandardNumberComponent extends ControlComponent {
  constructor(public livechartsService: LivechartsService) {
    super(livechartsService);
  }
}
