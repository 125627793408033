import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material/material.module';
// Service
import { ControlsService } from './controls/controls.service';
// Components
import { BasicEditorComponent } from './basic-editor.component';
import { ControlsComponent } from './controls/controls.component';
import { ControlGroupComponent } from './controls/control-group/control-group.component';
import { ControlWrapperComponent } from './controls/control-wrapper/control-wrapper.component';
// Controls
import { StandardControlComponent } from './controls/standard-control/standard-control.component';
import { StandardNumberComponent } from './controls/standard-number/standard-number.component';
import { StandardCheckboxComponent } from './controls/standard-checkbox/standard-checkbox.component';
import { StandardSelectComponent } from './controls/standard-select/standard-select.component';
import { StandardTextareaComponent } from './controls/standard-textarea/standard-textarea.component';
import { StandardPhotoComponent } from './controls/standard-photo/standard-photo.component';
import { StandardSlideComponent } from './controls/standard-slide/standard-slide.component';
import { StandardSliderComponent } from './controls/standard-slider/standard-slider.component';
import { PlaybackComponent } from './playback/playback.component';
import { ScrubComponent } from './scrub/scrub.component';
import { TimerComponent } from './timer/timer.component';
import { SettingsComponent } from './settings/settings.component';
import { FrameRateComponent } from './settings/frame-rate/frame-rate.component';
import { ScalableComponent } from './settings/scalable/scalable.component';
import { SlicesComponent } from './settings/slices/slices.component';
import { RenderVideoDialogComponent } from './render-video-dialog/render-video-dialog.component';

@NgModule({
  declarations: [
    BasicEditorComponent,
    ControlsComponent,
    ControlGroupComponent,
    ControlWrapperComponent,
    StandardControlComponent,
    StandardNumberComponent,
    StandardCheckboxComponent,
    StandardSelectComponent,
    StandardTextareaComponent,
    StandardPhotoComponent,
    StandardSlideComponent,
    StandardSliderComponent,
    PlaybackComponent,
    ScrubComponent,
    TimerComponent,
    SettingsComponent,
    FrameRateComponent,
    ScalableComponent,
    SlicesComponent,
    RenderVideoDialogComponent,
  ],
  entryComponents: [
    StandardControlComponent,
    StandardNumberComponent,
    StandardCheckboxComponent,
    StandardSelectComponent,
    StandardTextareaComponent,
    StandardPhotoComponent,
    StandardSlideComponent,
    StandardSliderComponent,
    RenderVideoDialogComponent,
  ],
  imports: [BrowserModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [BasicEditorComponent],
  providers: [ControlsService],
})
export class BasicEditorModule {}
