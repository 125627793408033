export let settings = {
  client: 'Dogfight',
  project: 'Livecharts',
  fonts: [
    {
      type: 'google',
      url:
        'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap',
      fontFamily: 'Open Sans',
    },
  ],
};
