import { AutofillMonitor } from '@angular/cdk/text-field';

export let params = {
  parameters: {
    size: {
      default: [1080, 1080],
      label: 'Afmeting',
      values: [
        {
          value: [1080, 1080],
          label: '1080 x 1080',
        },
        {
          value: [600, 600],
          label: '600 x 600',
        },
      ],
      control: 'standard-select',
      description: 'Afmeting',
    },
    width: {
      default: 1080,
      label: 'Breedte',
    },
    height: {
      default: 1080,
      label: 'Hoogte',
    },
    photo: {
      default: null,
      label: 'Foto',
      control: 'standard-photo',
    },
    text: {
      default:
        'HET IS DIGITAL, HET IS EEN DIGITALE ACTIVITEIT, EN DAT MAAKT HET ZO ANDERS IN DE BENADERING.',
      label: 'Textarea',
      control: 'standard-textarea',
      // required: true,
      // description: 'Excellent description',
      // hint: 'Meaningful look',
    },
    textAnchor: {
      default: 'middle',
      label: 'Uitlijning tekst',
      values: [
        {
          value: 'middle',
          label: 'midden',
        },
        {
          value: 'start',
          label: 'links',
        },
        // {
        //   value: 'end',
        //   label: 'rechts',
        // },
      ],
      control: 'standard-select',
     },
    textColor: {
      label: 'Kleur tekst',
      default: '#FFFFFF',
      required: true,
      control: 'standard-control',
    },
    fontFamily: {
      default: 'Open Sans',
      label: 'Font tekst',
      values: [
        {
          value: 'Open Sans',
          label: 'Open Sans',
        },
      ],
      control: 'standard-select',
    },
    blurImage: {
      label: 'Blur image',
      default: 15,
      required: false,
      min: 0,
      max: 30,
      step: 1,
      control: 'standard-number',
    },
    distanceFromBottom: {
      label: 'Afstand vanaf onderkant ',
      default: 100,
      required: true,
      min: 0,
      max: 1080,
      step: 10,
      control: 'standard-number',
    },
    rectangleAngle: {
      label: 'Hoek achtergrond',
      default: -3,
      min: -15,
      max: 15,
      step: 1,
      thumbLabel: true,
      control: 'standard-slider',
    },
    widthRect: {
      label: 'Breede banner ',
      default: 1080,
      required: true,
      min: 500,
      max: 1080,
      step: 10,
      control: 'standard-number',
    },
    rectangleMargin: {
      label: 'Marge rond tekst boven en onder',
      default: 30,
      required: true,
      min: 0,
      max: 200,
      step: 10,
      control: 'standard-number',
    },
    rectangleMarginSide: {
      label: 'Marge rond tekst rechts en links',
      default: 200,
      required: true,
      min: 50,
      max: 350,
      step: 10,
      control: 'standard-number',
    },
    rectangleColor: {
      label: 'Kleur achtergrond',
      default: '#a12422',
      required: true,
      control: 'standard-control',
    },
    fillLogo: {
      default: false,
      label: 'Kleur Dogfight logo',
      values: [
        {
          value: false,
          label: 'kleur',
        },
        {
          value: true,
          label: 'wit',
        },
      ],
      control: 'standard-select',
    },
    backgroundLogo: {
      default: 'none',
      label: 'Kies achtergrond Dogfight logo',
      values: [
        {
          value: 'none',
          label: 'geen',
        },
        {
          value: '#b10f1f',
          label: 'rood',
        },
        {
          value: '#00609c',
          label: 'blauw',
        },
      ],
      control: 'standard-select',
    },
    animationMic: {
      default: 'slideFromTop',
      label: 'Select settings microfoon',
      values: [
        {
          value: 'slideFromTop',
          label: 'Animatie: Slide van boven',
        },
        {
          value: 'slideFromLeft',
          label: 'Animatie: Slide van links',
        },
        {
          value: 'bounceFromTop',
          label: 'Animatie: Stuiter van boven',
        },
        {
          value: 'none',
          label: 'Animatie: Geen',
        },
        {
          value: 'doNotShowAtAll',
          label: 'Niet tonen',
        },
      ],
      control: 'standard-select',
     },
    bla: {
      label: 'Bla',
      default: 'Some text...',
      // description: 'Tooltip with a description of what this parameter does.',
      // hint: 'This is a hint! Get it?',
      required: true,
      // TODO: implement validation below!
      validations: [
        value =>
          value.length >= 5
            ? null
            : `Value: ${value} of length ${value.length} too short, minimum length is 5.`,
      ],
      control: 'standard-control',
    },
    check: {
      label: 'Check',
      default: false,
      control: 'standard-checkbox',
      description: 'NOT IMPLEMENTED',
      hint: 'NOT IMPLEMENTED',
    },
    slide: {
      label: 'Slide me',
      default: true,
      color: 'accent', // e.g. primary, accent, warn
      control: 'standard-slide',
      labelPosition: 'before',
      description: 'NOT IMPLEMENTED',
      hint: 'NOT IMPLEMENTED',
    },
    slider: {
      label: 'Slider me',
      default: 44,
      min: 20,
      max: 60,
      step: 1,
      thumbLabel: true,
      // tickInterval: 'auto',
      control: 'standard-slider',
      description: 'NOT IMPLEMENTED',
      hint: 'NOT IMPLEMENTED',
    },
  },
  timings: {
    scene1: {
      label: 'Scene 1',
      default: 1,
      min: 0,
      step: 0.25,
      control: 'standard-number',
    },
    pause1: {
      label: 'Pause 1',
      default: 2,
      min: 0,
      step: 0.25,
      control: 'standard-number',
    },
    scene2: {
      label: 'Scene 2',
      default: 1.5,
      min: 0,
      step: 0.25,
      control: 'standard-number',
    },
  },
  parameterGroups: [
    {
      title: 'Primair',
      // description: 'More info on this control group',
      collapsed: false,
      ids: [
        'size',
        'photo',
        'text',
        'textAnchor',
        'rectangleColor',
        'distanceFromBottom',
        'rectangleAngle',
        'widthRect',
        'animationMic',
        'fillLogo',
        'backgroundLogo'
      ],
      // ids: ['size', 'select', 'bla', 'slide', 'slider', 'something'],
    },
    {
      title: 'Secundair',
      collapsed: true,
      ids: ['rectangleMarginSide'],
    },
  ],
  timingGroups: [
    {
      title: 'Group 1',
      collapsed: false,
      ids: ['scene1', 'pause1', 'scene2'],
    },
  ],
  metadata: {
    category: 'animation',
    outputTypes: ['png', 'video'],
    fonts: [
      {
        fontFamily: 'Open Sans',
        stylesAndWeights: [{ style: 'normal', weight: 400 }],
      },
    ],
    backgroundColor: '#FF1',
  },
};
