// Default settings, can be overridden in the projects
export let settings = {
  client: 'The Brighthouse',
  project: 'Livecharts',
  urls: {
    renderVideoOnServer: 'wss://render.livecharts.nl', // ws://localhost:3001
  },
  frameRate: {
    min: 24,
    max: 60,
    default: 30,
  },
  editor: {
    scalable: true,
    playing: 'pause',
    slices: true,
  },
  overview: {
    scalable: true,
    playing: 'pause',
    slices: false,
  },
  // scalable: {
  //   overview: true,
  //   editor: true,
  // },
  showSlices: true,
  slices: {
    fill: 'none',
    stroke: '#D00',
    strokeWidth: '1',
    strokeDashArray: '4',
  },
  // playing: {
  //   overview: 'pause',
  //   editor: 'pause',
  // },
  paths: {
    overview: 'overview',
    editor: 'editor',
  },
  fonts: [],
};
