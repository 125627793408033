import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material/material.module';
// Services
import { LivechartsService } from '../../livecharts.service';
// Components
import { BasicOverviewComponent } from './basic-overview.component';
import { BasicOverviewElementComponent } from './basic-overview-element/basic-overview-element.component';

@NgModule({
  declarations: [BasicOverviewComponent, BasicOverviewElementComponent],
  imports: [BrowserModule, MaterialModule],
  exports: [BasicOverviewComponent],
  providers: [LivechartsService],
})
export class BasicOverviewModule {}
