import { Component } from '@angular/core';
import { ControlComponent } from '../base/control.component';
import { LivechartsService } from '../../../../livecharts.service';

@Component({
  selector: 'lib-standard-slider',
  templateUrl: './standard-slider.component.html',
  styleUrls: ['./standard-slider.component.scss'],
})
export class StandardSliderComponent extends ControlComponent {
  constructor(public livechartsService: LivechartsService) {
    super(livechartsService);
  }
}
